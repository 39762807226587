import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, useNavigate, useLocation } from "react-router-dom";
import { open, close } from '../redux/popupSlice';


import PopupMain from './popup/PopupMain';
import * as api from '../libs/api';
import { regPassword } from "../libs/utils";

export default function FindPage() {
    const location = useLocation();
    const dispatch = useDispatch();
    let tab_name = 'id';
    if(location.pathname == '/find/pw'){
        tab_name = 'pw';
    }
    
    let [box, setBox] = useState('find');
    const [text, setText] = useState('본인인증으로');
    let [tab, setTab] = useState(tab_name);
    const [type, setType] = useState('phone');
    let [member, setMember] = useState(false);
    const [message, setMessage] = useState('');
    const [return_text, setReturnText] = useState('가입된 정보가 없습니다.');

    
    const [pw1, setPw1] = useState();
    const [pw2, setPw2] = useState();
    const [name, setName] = useState();
    const [id, setId] = useState();
    const [ci, setCi] = useState();
    const [email1, setEmail1] = useState();
    const [email2, setEmail2] = useState();
    const [time_min, setTime] = useState(180);
    const [send, setSend] = useState(false);
    const [rnd, setRnd] = useState();
    

    const navigate = useNavigate();
    useEffect(() => {
        setTab(tab);
        setRnd("");

        const childMessage = (e) => {
            if (e.data.type != 'agree') {
              return
            }
            
            const auths = JSON.parse(localStorage.getItem("sendData"));
            //let redata = location.state;
            api.postData("/find", {
                type:type,
                tab:tab,
                name:auths?.RSLT_NAME,
                email:auths?.CI
            }).then(res=>{
                if(res && res?.data?.status === "SUCCESS"){
                    setMessage("");
                    if(tab=="id"){
                        setMember(true);
                        setReturnText(res.data.data.email)
                    }else{
                        setMember(false);
                        member = false;
                        if(type==="phone"){
                            box = "reset";
                            setBox("reset");
                        }
                    }
                }else{
                    dispatch(
                        open({
                            component: <PopupMain 
                                text={'창비교육 홈페이지에 가입되어 있지 않습니다.\n\n회원가입을 진행해 주세요.'}
                                button={'확인'}
                            />
                        })
                    );
                }
            });
            

        }
        window.addEventListener('message', childMessage);
        return () => {
            window.removeEventListener('message', childMessage);
        };
    }, [tab]);

    
    function goUrl(url) {
        navigate(url);
    }
    const onTab = (txt) => {
        setMember(false);
        setTab(txt)
        tab = txt;
        setMessage("");
        if(txt == 'id'){
            setType('phone');
        }
    }


    const [mdl_tkn, SetMdlTkn] = useState("");
    const formRef = useRef(null);
    const onFindId = () => {

        if(type=="phone"){
            api.getData("/auth/send", {}).then(res=>{
                SetMdlTkn(res.data);
                setTimeout(function(){
                    window.open("", "auth_popup", "width=430,height=640,scrollbars=yes");
                    formRef.current.cp_cd.value = "P08100000000";
                    formRef.current.mdl_tkn.value = res.data;
                    formRef.current.tc.value = "kcb.oknm.online.safehscert.popup.cmd.P931_CertChoiceCmd";
                    formRef.current.action = "https://safe.ok-name.co.kr/CommonSvl"
                    formRef.current.target = "auth_popup";
                    formRef.current.submit();    
                },100)
            });

        }
    }
    const onFindPw = () => {
        
        if(!rnd){
            dispatch(
                open({
                    component: <PopupMain 
                        text='인증번호를 입력하세요.'
                        button={'확인'}
                    />
                })
            );  
            return;
        }
        
        if(!name){
            dispatch(
                open({
                    component: <PopupMain 
                        text='이름을 입력하세요.'
                        button={'확인'}
                    />
                })
            );  
            return;
        }

        if(!email1 || !email2){
            dispatch(
                open({
                    component: <PopupMain 
                        text='이메일을 입력하세요.'
                        button={'확인'}
                    />
                })
            );  
            return;
        }
        api.postData("/phone", {
            type:type,
            tab:tab,
            rnd:rnd,
            email:email1+"@"+email2
        }).then(res=>{
            if(res && res?.data?.status === "SUCCESS"){
                
  
                if(res.data.data ) {
                    var tDate = new Date()
                    tDate.setMinutes(tDate.getMinutes()-3);
                    var cDate = new Date(res.data.data)
                    
                    if ( cDate.getTime() < tDate.getTime() ){
                        dispatch(
                            open({
                                component: <PopupMain 
                                    text='인증시간이 초과되었습니다.'
                                    button={'확인'}
                                />
                            })
                        ); 
                    } else {
                        setBox("reset");
                    }
                }else{
                    dispatch(
                        open({
                            component: <PopupMain 
                                text='인증번호를 확인해주세요.'
                                button={'확인'}
                            />
                        })
                    ); 
                }

            }else{
                setMessage("인증번호가 일치하지 않습니다.");
            }
        });
        
    }
    const onResetPw = () => {
        if(pw1.match(regPassword)===null) {
            window.alert("비밀번호는 영문,숫자,특수문자(!@#$%^*+=-) 혼합 8~15자 사이로 입력해주세요.");
            return;
        }
        if(pw1!=pw2) {
            window.alert("비밀번호를 확인해주세요.");
            return;
        }

        const auths = JSON.parse(localStorage.getItem("sendData"));
        api.postData("/change", {
            type:type,
            tab:tab,
            pw1:pw1,
            pw2:pw2,
            name:(auths?.RSLT_NAME ? auths?.RSLT_NAME : name),
            ci:(auths?.CI ? auths?.CI : ''),
            email:(email1 ? email1+"@"+email2 : ''),
            rnd:rnd
        }).then(res=>{

            if(res && res?.data?.status === "SUCCESS"){
                if(res?.data?.data?.result){
                    goUrl("/login");
                    dispatch(
                        open({
                            component: <PopupMain 
                                text='비밀번호가 변경되었습니다.'
                                button={'확인'}
                            />
                        })
                    );
                }else{
                    dispatch(
                        open({
                            component: <PopupMain 
                                text='비밀번호를 확인해주세요.'
                                button={'확인'}
                            />
                        })
                    );  
                }
            } else{
                dispatch(
                    open({
                        component: <PopupMain 
                            text='관리자에게 문의해주세요.'
                            button={'확인'}
                        />
                    })
                );

            }
        });


        //goUrl("/login");
    }
    const onAuthNo = () => {

        if(!name){
            dispatch(
                open({
                    component: <PopupMain 
                        text='이름을 입력하세요.'
                        button={'확인'}
                    />
                })
            );  
            return;
        }

        if(!email1 || !email2){
            dispatch(
                open({
                    component: <PopupMain 
                        text='이메일을 입력하세요.'
                        button={'확인'}
                    />
                })
            );  
            return;
        }
        setRnd("");
        api.postData("/find", {
            type:type,
            tab:tab,
            name:name,
            email:email1+"@"+email2
        }).then(res=>{
            if(res && res?.data?.status === "SUCCESS"){
                dispatch(
                    open({
                        component: <PopupMain 
                            text='인증번호를 발송했습니다.'
                            button={'확인'}
                        />
                    })
                );
                setSend(true);
                let min = 180;
                const times = setInterval(function(){
                    var hours   = Math.floor(min / 3600);
                    var minutes = Math.floor((min - (hours * 3600)) / 60);
                    var seconds = min - (hours * 3600) - (minutes * 60);
                    
                    if (hours   < 10) {hours   = "0"+hours;}
                    if (minutes < 10) {minutes = "0"+minutes;}
                    if (seconds < 10) {seconds = "0"+seconds;}
                    setTime(minutes+":"+ seconds)
                    min--;
                    if(min <= 0){
                        clearInterval(times);
                        setSend(false);
                        setMessage("");
                        setRnd("");
                        
                    }
                },1000)

            }else{
                dispatch(
                    open({
                        component: <PopupMain 
                            text={'창비교육 홈페이지에 가입되어 있지 않습니다.\n\n회원가입을 진행해 주세요.'}
                            button={'확인'}
                        />
                    })
                );
            }
        });
    }
    const onPwChange = (e,no) => {
        if(no === 1){
            setPw1(e.target.value);    
        }
        if(no === 2){
            setPw2(e.target.value);
        }
        
    }

    return (
        <div className="login_back changbi">
        <button type="button" onClick={()=>goUrl("/")} className="m_close" ><img src='/images/m_close.svg' /></button>
            <form ref={formRef} method="post" >
                <input type="hidden" name="cp_cd" />
                <input type="hidden" name="mdl_tkn"/>
                <input type="hidden" name="tc"/>
            </form>
                        
            <div className={"login_box find-box " + (box=="find" ? "show" : "hide") }>           
                <div className="tab">
                    <button type="button" className={tab=='id' ? "active" : ""} onClick={()=>onTab('id')} >아이디 찾기</button>
                    <button type="button" className={tab=='pw' ? "active" : ""} onClick={()=>onTab('pw')} >비밀번호 찾기</button>
                </div>
                <div className={"no-find " + (member ? "show" : "hide")}>
                    {return_text}
                </div>
                <div className={member ? "hide" : "show"}>
                    <div className="input-radio">
                        <label className="radio"><input type="radio" name="type" checked={type=='phone'} onClick={()=>{setType('phone');}} defaultChecked/><span>본인인증으로 찾기</span></label>

                        {tab=='pw' ? <label className="radio"><input type="radio" checked={type=='email'} name="type" onClick={()=>setType('email')}/><span>이메일로 찾기</span></label> : ""}
                        
                    </div>
                    <div className={"input-text " + (type=='email' ? "show" : "hide")}>
                        <label>성명</label>
                        <input type="text" onChange={(e)=>setName(e.target.value)}/>
                    </div>
 
                    <div className={"input-email " + (type=='email' ? "show" : "hide")}>
                        <label>이메일</label>
                        <div>
                            <input type="text" onChange={(e)=>setEmail1(e.target.value)} />
                            <span>@</span>
                            <input type="text" onChange={(e)=>setEmail2(e.target.value)} />
                        </div>
                    </div>

                    <div className={"input-auth " + (type=='email' && tab=='pw' ? "show" : "hide")}>
                        <button type="button" className="btn-auth" onClick={()=>onAuthNo()}>인증번호 받기</button>                    
                        <div className={"receive-auth " + (send ? "flex" : "hide")}>
                            <label>인증번호 입력</label>
                            <div>
          
                                <div className="enter-auth">
                                    <input type="text" value={rnd} onChange={(e)=>setRnd(e.target.value)} />
                                    <span>{time_min}</span>
                                    <button type="button" onClick={onFindPw}>확인</button>
                                </div>                    
                            </div>
                        </div>
                        <small className={message!='' ? "show" : "hide"}>{message}</small>
                    </div>
                    <button type="button"  className={"btn " + (tab=='id' || (tab=='pw' && type=="phone") ? "show" : "hide")} onClick={()=>onFindId()}>{text} 찾기</button>
                </div>                
            </div>
            
            <div className={"login_box reset-box " + (box=="reset" ? "show" : "hide") }>          
                <p>비밀번호를 재설정해 주세요.</p>
                <div className="input-pw">
                    <label>비밀번호</label>
                    <input type="password" onChange={(e) => onPwChange(e,1)} placeholder="8~15자리 영문,숫자,특수문자(!@#$%^*+=-) 혼합"/>
                    <input type="password" onChange={(e) => onPwChange(e,2)} placeholder="비밀번호 재입력"/>
                </div>
                <button type="button" className="btn" onClick={()=>onResetPw()}>확인</button>
            </div>
        </div>
    );
}
