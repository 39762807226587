import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router';
import { Routes, useNavigate,useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { logIn, logOut  } from '../redux/usersSlice';
import routes from '../libs/routes';
import images from "../libs/images";
import * as api from '../libs/api';
import PopupMain from './popup/PopupMain';
import { open, close } from '../redux/popupSlice';
import { useCookies } from 'react-cookie';
import NaverLogin from '../components/naver_login';
import KakaoLogin from '../components/kakao_login';
import PopupRegister from './popup/PopupRegister'


export default function LoginPage(props) {
    const params = useParams();
    const { mbData, accessToken, autoLogin } = useSelector(s => s.usersReducer);
    const [login, setLogin] = useState({id:"",pw:""}); 
    
    const [pwtype, setPwType] = useState("password"); 
    const [pw_image, onPwImage] = useState(images.input_view); 
    
    const [input_label, setInputLabel] = useState(""); 
    const [pw_label, setPwLabel] = useState(""); 
    
    const [isRemember, setIsRemember] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(["rememberUserId"]);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation(); 
    
    const [returnEmail, setReturnEmail] = useState("");
    
    function goUrl(url) {
        navigate(url);
    }
    const onLogin = () => {
        api.postData("/signin", {
            account:login.id,
            pw:login.pw
        }).then(res=>{
            localStorage.removeItem("access");
            localStorage.removeItem("remail");
            localStorage.removeItem("page");
            localStorage.removeItem("sendData");
            if(res && res?.data?.status=="SUCCESS"){
                if(res?.data?.data?.accessToken === "new"){
                    localStorage.setItem("remail",res?.data?.data?.email);
                    dispatch(
                        open({
                            component: <PopupMain
                                func="new"
                                width={400}
                                text="홈페이지 개편으로 인해 본인인증 후 비밀번호 변경이 필요합니다."
                                button="확인"
                                buttonCancel="취소"
                            />,
                        })
                    );
                }else{
                    dispatch(
                        logIn({
                            mbData: res.data.data
                        })
                    );
                    localStorage.setItem("access", res.data.data.accessToken);
                    //localStorage.setItem("refresh", res.data.data.refreshToken);

                    api.getDataRefresh("/book", {
                        memberSeq:res.data.data.userSeq
                    },res.data.data).then(res2=>{
                        if(res2 && res2?.data?.status=="SUCCESS"){
                            props?.setQuickMyBook(res2.data.data);               
                        }
                    });

                    if(isRemember){
                        setCookie("rememberUserId", login.id);
                    }else {
                        removeCookie("rememberUserId");
                    }
                    if(location?.state?.url){
                        navigate(location.state.url);
                    }else{
                        navigate("/");
                    }
                }
            }else{
                dispatch(
                    open({
                        component: <PopupMain
                            width={400}
                            text="아이디 / 비밀번호를 확인해주세요."
                            button="닫기"
                        />,
                        
                    })
                );
            }
        });
    }

    const onEmailChange = (e) => {
        setInputLabel("");
        if(e.target.value !== ''){
            setInputLabel("active");
        }
        setLogin({...login, id:e.target.value})
    }
    const onPwChange = (e) => {
        setPwLabel("");
        if(e.target.value !== ''){
            setPwLabel("active");
        }
        setLogin({...login, pw:e.target.value});
    }

    const onInputTextRemove = (e) => {
        setInputLabel("");
        setLogin({...login, id:""})
    }

    const onPwView = (e) => {
        if(pwtype === 'text'){
            setPwType("password");
            onPwImage(images.input_view);
        } else {
            setPwType("text");
            onPwImage(images.input_view_off);
        }
    }

    const RegisterPopup = (type,email) => {
        
        if(type === 'logincheck') {
            dispatch(
                open({
                    component: <PopupRegister
                        title=""
                        message={"이미 가입된 회원입니다.\n\n아이디"}
                        id={email}
                        onPress={() => {
                            navigate(routes.login);
                            dispatch(close());
                        }}
                        onPressText="로그인"
                        />
                })
            )
        }
        if(type === 'ok') {
            dispatch(
                open({
                    component: <PopupRegister
                        title=""
                        message={"가입이 완료되었습니다. 로그인 후 이용해주세요."}
                        id=""
                        onPress={() => {
                            navigate(routes.login);
                            dispatch(close());
                        }}
                        onPressText="확인"
                        />
                })
            )
        }
        if(type === 'confirm') {
            dispatch(
                open({
                    component: <PopupRegister
                        title=""
                        message={"해당 자료는 선생님 회원만\n이용하실 수 있습니다.\n마이 페이지에서 선생님 인증을 해주세요."}
                        id=""
                        onPress={() => {
                            window.location.reload();
                        }}
                        onPressText="확인"
                        />
                })
            )
        }
    };    

    useEffect(() => {
        if (cookies.rememberUserId !== undefined) {
            setLogin({ ...login, id: cookies.rememberUserId });
            setIsRemember(true);
        }

      
        const childMessage = (e) => {
            if (e.data.type != 'agree') {
              return
            }

            if( e?.data?.payload?.CI && e?.data?.payload?.CI!==''){
                dispatch(close());
                navigate("/check",{state:e.data});
            }else{
                dispatch(
                    open({
                        component: <PopupMain
                            width={400}
                            text="본인인증을 실패하셨습니다. 다시 인증을 해주세요."
                            button="로그인"
                            buttonCancel="닫기"
                            func="login"
                            url={location.pathname}
                        />
                    })
                );
            }
        }
        window.addEventListener('message', childMessage);
        return () => {
            window.removeEventListener('message', childMessage);
        };  
    }, []);
    /*
    useEffect(() => {
        const childMessage = (e) => {

            if (e.data.type !== 'kakao' && e.data.type !== 'naver') {
              return;
            }
            let sns = e.data.payload;
            if(e.data.type === 'kakao'){
                sns['snsType'] = 'kakao';
            }
            if(e.data.type === 'naver'){
                sns['snsType'] = 'naver';
            }
            api.postData("/signin/sns", {
                account:sns.id,
                pw:sns['snsType']
            }).then(res=>{
                /*
                localStorage.clear() 
                if(res && res?.data?.status=="SUCCESS"){
                    dispatch(
                        logIn({
                            mbData: res.data.data
                        })
                    );
                    
                    localStorage.setItem("access", res.data.data.accessToken);
                    if(isRemember){
                        setCookie("rememberUserId", login.id);
                    }else {
                        removeCookie("rememberUserId");
                    }
                    if(location?.state?.url){
                        navigate(location.state.url);
                    }else{
                        navigate("/");
                    }
                }else{
                    navigate(routes.register01,{state:sns})
                }
                
            });            
        }
        window.addEventListener('message', childMessage);
        return () => {
            window.removeEventListener('message', childMessage);
        };
    },[]);
    */
    

    return (
        <div className="login_back">
            
            <div className="login_box">
                <button type="button" onClick={()=>goUrl("/")} className="m_close" ><img src='/images/m_close.svg' /></button>
                <p className="title">로그인</p>

                <div className="login_input_box">
                    <div className={`input_text ${input_label ? input_label : ''}`}>
                        <label>이메일</label>
                        <input type="text" placeholder="이메일(ID)" value={login.id} onChange={(e)=>onEmailChange(e)}/>
                        <button type="button" className={input_label} onClick={(e)=>onInputTextRemove(e)}><img src='/images/close-circle-fill.svg' /></button>
                        {/*<small>가입된 이메일주소가 아닙니다.</small>*/}
                    </div>
                    <div className={`input_text _pw ${pw_label ? pw_label : ''}`}>
                        <label>비밀번호</label>
                        <input type={pwtype} placeholder="비밀번호" onChange={(e)=>onPwChange(e)}/>
                        <button type="button" className={pw_label}><img src={pw_image} onClick={(e)=>onPwView(e)} /></button>
                        {/*<small>비밀번호가 올바르지 않습니다.</small>*/}
                    </div>

                    <div className="checkbox">
                        <label><input type="checkbox" id="id_save" checked={isRemember} className="input_chk" onClick={(e)=>setIsRemember(!isRemember)}/> 아이디 저장</label>
                    </div>
                </div>

                <button type="button" className="login_btn" onClick={()=>onLogin()}>로그인</button>

                <div className="login_help_box">
                    <p onClick={()=>goUrl('/find/id')}>아이디 찾기</p>
                    <span style={{color: '#ccc', margin: '0 8px'}}>|</span>
                    <p onClick={()=>goUrl('/find/pw')}>비밀번호 찾기</p>
                </div>

                <div className="sns_login_box">
                    <KakaoLogin />
                    <p className="naver"><NaverLogin  /></p>
                </div>

                <p className="sub_title">계정이 없으세요?</p>
                
                <button type="button" className="join_btn" onClick={() => goUrl(routes.register01)}>회원가입</button>
            </div>
        </div>
    );
}
