import {combineReducers} from 'redux';
import usersReducer from './usersSlice';
import popupReducer from './popupSlice';
import loadingReducer from './loadingSlice';
import etcReducer from './etcSlice';

export default combineReducers({
  usersReducer,
  popupReducer,
  loadingReducer,
  etcReducer,
});
