import React, { useEffect, useState, useRef } from 'react';
import { Routes, useNavigate, useLocation, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

export default function KakaoLogin(props) {

    const { Kakao } = window;
  
    const Rest_api_key='1b608287b31e72bbb1ecd455087995d5' //REST API KEY
    let redirect_uri = process.env.REACT_APP_URL+'/sns/kakao' //Redirect URI
    if(props.type ){
      redirect_uri = process.env.REACT_APP_URL+'/sns/kakao/'+props.type //Redirect URI
    }
    

    // oauth 요청 URL
    const kakaoURL = `https://kauth.kakao.com/oauth/authorize?client_id=${Rest_api_key}&redirect_uri=${redirect_uri}&response_type=code`
    const handleLogin = ()=>{

        window.open(kakaoURL,"kakao","width=500,height=500");
    }


/*
    const initKakao = () => {
        if (Kakao && !Kakao.isInitialized()) {
          Kakao.init('9cb4c560e2443e10963ccd8dc5b14b75')
        }
      }
      
      useEffect(() => {
        initKakao()
      }, [])
      
      const kakaoLoginHandler = () => {
        Kakao.Auth.authorize({
          redirectUri: process.env.REACT_APP_URL+'/sns/kakao',
        })
      }
    */
    return (
      <>
        {props.type ? 
        
        <button type="button" onClick={()=>handleLogin()}>연결하기</button> 
        :
        <p className="kakao" onClick={()=>handleLogin()}></p>
        }
      </>
    );
}
