import React, { useEffect, useState, useRef, forwardRef } from "react";
import { Routes, useNavigate, useLocation, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import images from "../../libs/images";
import routes from "../../libs/routes";
import { logIn, logOut, setData as setUserData } from '../../redux/usersSlice';
import { open, close } from '../../redux/popupSlice';
import { loadingStart, loadingEnd } from '../../redux/loadingSlice';
import * as api from '../../libs/api';
import { imageUrl } from "../../libs/utils";
import BtnScrap from '../../components/btn_scrap';
import PopupMain from './PopupMain';

export default function PopupMyBook(props) {
    const { mbData, accessToken, autoLogin,mbBook } = useSelector(s => s.usersReducer);
    const location = useLocation(); 
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [list, setList] = useState([]);
    let [parent_list, setParentList] = useState([]);
    let [subject, setSubject] = useState("국어");
    let [school, setSchool] = useState("중학");
    let [year, setYear] = useState();
    const [year_list, setYearList] = useState([]);
    
    useEffect(() => {
        onYear(school,subject);
        //setParentList(props.list);
        //parent_list = props.list;
    },[]); 

    
    const changeYear = (val) => {
        setYear(val);
        onList(val,school,subject);
    }
    const changeSubject = (val) => {
        setSubject(val);
        onList(year,school,val);
    }
    const changeSchool = (val) => {
        setSchool(val);
        onYear(val,subject)
        onList(year,val,subject);
    }
    const onYear = (school,subject) => {
        api.getData("/curriculum/year", {
            school:school,
            subject:subject
        }).then(res=>{
            if(res && res?.data?.status=="SUCCESS"){
                setYear(res.data.data[0]?.seq);
                setYearList(res.data.data);
                
             

                onList(res.data.data[0]?.seq, school, subject);
            } 
        });
    }
    const onMyBookRemove = (val) => {

        //const seq = parent_list.find(par=>par.subjectSeq === val.subjectSeq)?.seq
        const seq = 0;
        api.deleteDataRefresh("/book",{
            subjectSeq: val.subjectSeq,
            memberSeq: mbData.userSeq
        },mbData).then(res=>{
                    console.log("Aaaa");
            if(res?.data?.status==='SUCCESS'){
                const newMbBook = mbBook.filter(mb => mb.subjectSeq != val.subjectSeq );
                    
                dispatch(
                    setUserData({
                        key: 'mbBook',
                        value: newMbBook
                    })
                )
            }
        });
       
    }
    const onMyBook = (info) => {
        //교과서별
        let parm = {
            memberSeq:mbData.userSeq,
            subjectSeq:info.subjectSeq,
            school:info.school,
            subject:info.subject,
            yearSeq:info.yearSeq,
            year:year_list.find(par=>par.yearSeq===info.year).year,
            subjectName:info.name,
            subjectThumbnail:info.image,
        };
        let link= "/book";
        let msg="내 교과서";
        api.postDataRefresh(link ,parm,mbData).then(res=>{ 
            if(res && res?.data?.status=="SUCCESS"){

                //setScrapSeq(res.data.data.seq);
                const ret = res.data.data;
          
                //props.setList([...props.list, ret]);
                //setParentList([...props.list, ret]);
                
                setList(
                    list.map(val=>val.subjectSeq === ret.subjectSeq ? {...val, checked:true} : val)
                )
                props.setList([ret, ...props.list]);

                const newMbBook = mbBook.find(val => val.subjectSeq === info.subjectSeq)?.subjectSeq;
                if(newMbBook > 0 ){

                }else{
                    dispatch(
                        setUserData({
                            key: 'mbBook',
                            value: [parm, ...mbBook]
                        })
                    )
                }

            } else {
                dispatch(
                    open({
                        component: <PopupMain
                            width={400}
                            text={"이미 추가되었습니다."}
                            button="닫기"
                        />,
                    })
                );
            }
        });
    }


    const onList = (yea,sch,sub) =>{
        if(yea){
            year = yea;
        }
        if(sch){
            school = sch;
        }
        if(sub){
            subject = sub;
        }

        api.getData("/curriculum/subject", {
            school:school,
            subject:subject,
            year:year
        }).then(res=>{
            if(res && res?.data?.status=="SUCCESS"){
                const lists = res.data.data;
                
                lists.map((val,key) => props.list.find(par=>par.subjectSeq === val.subjectSeq)?.seq > 0 ? res.data.data[key]['checked']=true : '' )
                setList(lists);               
            }
        });
    }

    return (
        <>
            <div className="changbi pop _05">
                <h1>
                    내 교과서
                    <button type="button" onClick={() => dispatch(close())}><img src='/images/exit-icon.svg' /></button>
                </h1>

                <div className="form">
                    <div className="search">
                        <div className="radios">
                            <label className="t">학급</label>
                            <div>
                                <label className="radio"><input type="radio" onClick={()=>changeSchool("중학")} checked={school==='중학'}/><span>중학</span></label>
                                <label className="radio"><input type="radio" onClick={()=>changeSchool("고등")} checked={school==='고등'} /><span>고등</span></label>
                            </div>
                        </div>
                        <div className="radios">
                            <label className="t">교육과정</label>
                            <div>
                                {year_list.map((val)=>(
                                    <label className="radio"><input type="radio" value={val?.seq} checked={val?.seq===year} onClick={()=>changeYear(val?.seq)}/><span>{val.year}</span></label>
                                ))}
                            </div>
                        </div>
                        <div className="tag _02">
                            <label>교과</label>
                            <ul>
                                <li><button type="button" onClick={()=>changeSubject("국어")} className={subject==='국어' && 'active'}>국어</button></li>
                                <li><button type="button" onClick={()=>changeSubject("사회")} className={subject==='사회' && 'active'}>사회</button></li>
                                <li><button type="button" onClick={()=>changeSubject("미술")} className={subject==='미술' && 'active'}>미술</button></li>
                            </ul>
                        </div>
                    </div>
                    <div className="my">
                        {list.length > 0 &&
                        <ul className="scroll">
                            {list?.map((val)=>(
                            <>
 
                            <li className={val.checked ? 'active': ''}>
                                <img src={imageUrl+"subject?file="+val.image} width="64" height="80"/>
                                <div>
                                    <span>{val.school} | {year_list.find(par=>par.yearSeq===val.year).year}년 개정</span>
                                    <h2>{val.name}</h2>
                                </div>
                                {mbBook.find(mb=>mb.subjectSeq === val.subjectSeq)?.memberSeq > 0 ?
                                <button type="button" className="add_plus" onClick={(e)=>onMyBookRemove(val)}>삭제</button>
                                :
                                <button type="button" className="add_plus" onClick={(e)=>onMyBook(val)}><img src='/images/add-line.svg' /></button>                  
                                }
                            </li>
                            </>
                            ))}
                        </ul>
                        }
                    </div>
                    <div className="popup-btn">
                        <button type="button" onClick={() => dispatch(close())}>닫기</button>
                        {/*<button type="button">저장</button>*/}
                    </div>
                </div>
            </div>
        </>
    );
}
