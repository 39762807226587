import React, { useEffect, useState, useRef } from 'react';
import { Routes, useNavigate, useLocation, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { open, close } from '../redux/popupSlice';
import { logIn, logOut  } from '../redux/usersSlice';
import images from "../libs/images";
import * as api from '../libs/api';
import PopupMain from '../pages/popup/PopupMain';


export default function BtnScrap(props) {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { mbData, accessToken, autoLogin } = useSelector(s => s.usersReducer);
    const [scrap_seq, setScrapSeq] = useState();

    const myScrap = (e) => {
        if( !mbData?.email){
            //localStorage.clear() 
            localStorage.removeItem("access");
            localStorage.removeItem("remail");
            localStorage.removeItem("page");
            localStorage.removeItem("sendData");
            dispatch(
                logOut()
            );
            dispatch(
                open({
                    component: <PopupMain
                        width={400}
                        text="로그인이 필요한 서비스입니다."
                        button="로그인"
                        buttonCancel="닫기"
                        func="login"
                        url={location.pathname}
                    />
                })
            );
            return
        }
        if(mbData?.email){
 
  
                
                let parm = {};
                let link = "/scrap";
                let msg= "스크랩";
                if(props.type==1){
                    //수업고민사전
                    parm = {
                        type:1,
                        categorySeq:0,
                        categoryName:"수업 혁신",
                        typeSeq:props.info.dictionarySeq,
                        title:props.info.name,
                        category:"수업고민사전",
                        memberSeq:mbData.userSeq,
                        link:location.pathname,
                        file:props.info.addFile,
                        fileOri:props.info.addFileOri
                    };
                }else if(props.type==2){
                    //교과서 단원별
                    parm = {
                        type:2,
                        categorySeq:props.info.subjectSeq,
                        categoryName:props.info.subject,
                        typeSeq:props.info.curriculumSeq,
                        title:props.info.name,
                        category:props.info.subjectText,
                        memberSeq:mbData.userSeq,
                        link:location.pathname,
                        file:props.info.file,
                        fileOri:props.info.fileOri
                    };
                }else if(props.type==3 || props.type==5){
                    //교과서별
                    parm = {
                        memberSeq:mbData.userSeq,
                        subjectSeq:props.info.subjectSeq,
                        school:props.info.school,
                        subject:props.info.subject,
                        yearSeq:props.info.yearSeq,
                        year:props.info.year,
                        subjectName:props.info.name,
                        subjectThumbnail:props.info.image,
                    };
                    link= "/book";
                    msg="내 교과서";
                    
                }else if(props.type==4){
                    //게시판 창의적, 연계도서 선생님맞춤
    
                    parm = {
                        type:4,
                        categorySeq:props.info.categorySeq,
                        categoryName:(props.info.type == 0 ? '창의적 체험 활동' : props.info.type == 1 ? '수업 연계 도서' : '선생님 맞춤 자료'),
                        typeSeq:props.info.boardSeq,
                        title:props.info.title,
                        category:props.info.category,
                        memberSeq:mbData.userSeq,
                        link:location.pathname,
                        file:props.info.file,
                        fileOri:props.info.oriFile
                    };
                }else{
                    //이달의 수업
                    parm = {
                        type:0,
                        categorySeq:0,
                        categoryName:"수업 혁신",
                        typeSeq:props.info.innovationSeq,
                        title:props.info.title,
                        category:"이달의 수업",
                        memberSeq:mbData.userSeq,
                        link:location.pathname,
                        file:props.info.fileZip3,
                        fileOri:props.info.fileZipOri3
                    };
                }
                api.postDataRefresh(link ,parm,mbData).then(res=>{ 
                    if(res && res?.data?.status=="SUCCESS"){
                        if(res.data.data.seq){
                            setScrapSeq(res.data.data.seq);
                            dispatch(
                                open({
                                    component: <PopupMain
                                        width={400}
                                        text={"나의 교실 " + msg+"에 추가되었습니다."}
                                        button="닫기"
                                    />,
                                })
                            );
                        }else{
                            dispatch(
                                open({
                                    component: <PopupMain
                                        width={400}
                                        text={"이미 추가되었습니다."}
                                        button="닫기"
                                    />,
                                })
                            );
                        }
                    } else {
                        dispatch(
                            open({
                                component: <PopupMain
                                    width={400}
                                    text={"이미 추가되었습니다."}
                                    button="닫기"
                                />,
                            })
                        );
                    }
                });

        }else{
            localStorage.removeItem("access");
            localStorage.removeItem("remail");
            localStorage.removeItem("page");
            localStorage.removeItem("sendData");
            dispatch(
                logOut()
            );
            dispatch(
                open({
                    component: <PopupMain
                        func="login"
                        width={400}
                        text="로그인이 필요한 서비스입니다."
                        button="로그인"
                        buttonCancel="닫기"
                        url={location.pathname}
                    />,
                    
                })
            );         
        }
    }
         
    useEffect(() => { 

    }, []); 
    return (
        <>
            {props.type == 1 ? 
                <button type="button" onClick={(e)=>myScrap(e)}><img src='/images/button_download2.svg'  /></button>
            : props.type == 2 ?
                <button type="button" onClick={(e)=>myScrap(e)}><img src='/images/button_download2.svg'  /></button>
            : props.type == 3 ?
                <button type="button" className="my" onClick={(e)=>myScrap(e)}><img src='/images/add-line.svg' />내 교과서</button>
            : props.type == 5 ?
                <button type="button" className="add_plus" onClick={(e)=>myScrap(e)}><img src='/images/add-line.svg' /></button>
            :
                <>
                {/*<button type="button" onClick={(e)=>myScrap(e)}><img src='/images/icon_folder.svg />{scrap_seq > 0 ? '스크랩 취소' : '담기'}</button>*/}
                <button type="button" onClick={(e)=>myScrap(e)}><img src='/images/icon_folder.svg' />담기</button>
                </>
            }
        </>
    );
}
