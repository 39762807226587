import react from 'react';
import { Routes, useNavigate, useLocation, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { open, close } from '../redux/popupSlice';
import { logIn, logOut  } from '../redux/usersSlice';
import images from "../libs/images";
import * as api from '../libs/api';
import PopupMain from '../pages/popup/PopupMain';

export default function FileDownloadAll(props) {
    const location = useLocation();
    const dispatch = useDispatch();
    const { mbData, accessToken, autoLogin } = useSelector(s => s.usersReducer);

    const onAllDownload = (e) => {

        if( mbData?.grade > 0){

        }else{
            if( mbData?.userSeq > 0){
                dispatch(
                    open({
                        component: <PopupMain
                            func="teacher"
                            width={400}
                            text={"선생님 인증이 필요합니다.\n\n인증 후에도 안되시면 재로그인 후 시도해 주세요."}
                            button="인증하기"
                            buttonCancel="닫기"
                            url={location.pathname}
                        />,
                        
                    })
                );
            }else{
            
                localStorage.removeItem("access");
                localStorage.removeItem("remail");
                localStorage.removeItem("page");
                localStorage.removeItem("sendData");
                dispatch(
                    logOut()
                );
                dispatch(
                    open({
                        component: <PopupMain
                            width={400}
                            text="로그인이 필요한 서비스입니다."
                            button="로그인"
                            buttonCancel="닫기"
                            func="login"
                            url={location.pathname}
                        />
                    })
                );
            }
            return
        }

        props.list.map(val=>{

            val[props.name] && (
                api.blobData("/download" ,{
                    //filename:val[props.name].replace("/",".")        
                    filename:encodeURIComponent(val[props.name].trimEnd()),
                    folder:encodeURIComponent(props.folder)
                },mbData).then(res=>{
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download",`${val[props.ori].trimEnd()}`);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                })
            )
        })
    }
    const onAllDownloadCheck = (e) => {
        if( mbData?.grade > 0){

        }else{
            if( mbData?.userSeq > 0){
                dispatch(
                    open({
                        component: <PopupMain
                            func="teacher"
                            width={400}
                            text={"선생님 인증이 필요합니다.\n\n인증 후에도 안되시면 재로그인 후 시도해 주세요."}
                            button="인증하기"
                            buttonCancel="닫기"
                            url={location.pathname}
                        />,
                        
                    })
                );
            }else{
                localStorage.removeItem("access");
                localStorage.removeItem("remail");
                localStorage.removeItem("page");
                localStorage.removeItem("sendData");
                dispatch(
                    logOut()
                );
                dispatch(
                    open({
                        component: <PopupMain
                            width={400}
                            text="로그인이 필요한 서비스입니다."
                            button="로그인"
                            buttonCancel="닫기"
                            func="login"
                            url={location.pathname}
                        />
                    })
                );
            }
            return
        }
        if( props?.list?.filter(val=>val.checked && val[props.name]).length <= 0 ){
            dispatch(
                open({
                    component: <PopupMain
                        width={400}
                        text="다운로드할 항목을 선택해 주세요."
                        button="닫기"
                    />
                })
            );
            return
        }

        props.list.map(val=>{
            val.checked && val[props.name] && (
                api.blobData("/download" ,{
                    filename:encodeURIComponent(val[props.name].trimEnd()),
                    folder:encodeURIComponent(props.folder)

                    //filename:val[props.name].replace("/",".")
                },mbData).then(res=>{
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download",`${val[props.ori].trimEnd()}`);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                })
            )
        })
    }
    return (
        props.type==1 ?
            <button type="button" onClick={(e)=>onAllDownload(e)} ><img src='/images/icon-all.svg' />전체 다운로드</button>
        : props.type==2 ?
            <button type="button" onClick={(e)=>onAllDownloadCheck(e)}><img src='/images/icon-download.svg' width="20" />선택 다운로드</button>    
        :
            <button type="button" onClick={(e)=>onAllDownloadCheck(e)}><img src='/images/icon-download.svg' width="20" />다운로드</button>    
    );
}
