import React, { useEffect, useState, useRef } from 'react';
import { Routes, useNavigate, useLocation, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { open, close } from '../../redux/popupSlice';
import { logIn, logOut  } from '../../redux/usersSlice';
import * as api from '../../libs/api';
import { imageUrl } from "../../libs/utils";
import Pagination from 'react-js-pagination';
import PopupMain from "../popup/PopupMain";

export default function Notice() {
    const { id } = useParams();
    const { mbData, accessToken, autoLogin } = useSelector(s => s.usersReducer);
    const location = useLocation(); 
    const navigate = useNavigate();
    const dispatch = useDispatch();
    

    const [list, setList] = useState([]);
    const [comment_list, setCommentList] = useState([]);
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(1);
    const [limit, setLimit] = useState(12);
    const [info, setInfo] = useState();
    

    const onList = (page) => {
        api.getData("/event" ,{
            page:page-1,
            size:limit
        }).then(res=>{

            if(res && res?.data?.status=="SUCCESS"){
                setList(res.data.data.content);
                setPage(page-1);
                setTotal(res.data.data.totalElements);                   
            }
        });
        

    }
    useEffect(() => {
        onList(location?.state ? location?.state : 1); 
    }, []);
    return (
        <div className="changbi event">
            <div className="wrap">

                <div className="box">
                    <h1 className="title">이벤트</h1>
                    <div>
                        <div className="list-type4">
                            <ul>
                                {list && list.map((val) => (
                                <li>
                                    <Link to={"/event/"+val.eventSeq} state={(page+1)}>
                                        <img src={imageUrl+"board?file="+val.thumbnail} width="100%"  height="286" />
                                        <h2>{val.title}</h2>
                                        <p className="event"><small className={val.ing===1 ? 'ing' : 'end'}>{val.ing===1 ? '진행중' : '종료'}</small>{val?.registDate?.substr(0,10)?.replaceAll("-",".")}</p>
                                    </Link>
                                </li>
                                ))}
                            </ul>
                            
                            {list.length <= 0 && <p>진행중인 이벤트가 존재하지 않습니다.</p>}
                        </div>

                        <div className="paging">
                            <Pagination
                                activePage={page+1}
                                itemsCountPerPage={limit}
                                totalItemsCount={total}
                                pageRangeDisplayed={10}
                                itemClassFirst="page_first"
                                itemClassPrev="page_prev"
                                itemClassNext="page_next"
                                itemClassLast="page_last"
                                onChange={onList}
                            />
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    );
}
