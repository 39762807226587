import React, { useEffect, useState, useRef } from 'react';
import { Routes, useNavigate, useLocation, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { open, close } from '../../redux/popupSlice';
import { logIn, logOut  } from '../../redux/usersSlice';
import { setData as setEtcData } from '../../redux/etcSlice';
import * as api from '../../libs/api';
import { imageUrl, timeForToday,today } from "../../libs/utils";
import Pagination from 'react-js-pagination';
import PopupMain from "../popup/PopupMain";

export default function EventInfo() {
    const { id } = useParams();
    const { mbData, accessToken, autoLogin } = useSelector(s => s.usersReducer);
    const location = useLocation(); 
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const [comment, setComment] = useState();
    const [comment_list, setCommentList] = useState([]);
    const [info, setInfo] = useState();
    
    const onCommentRemove = (eventReplySeq) => {
        dispatch(
            open({
                component: <PopupMain
                    width={400}
                    text="삭제하시겠습니까?"
                    button="삭제"
                    buttonCancel="취소"
                    func="comment_remove"
                    url={location.pathname}
                    seq={eventReplySeq}
                    setFunc={setCommentList}
                    list={comment_list}
                />
            })
        );
    }
    const onCommentSave = () => {
        if(!comment){
            dispatch(
                open({
                    component: <PopupMain
                        width={400}
                        text="내용을 작성해주세요."
                        button="확인"
                    />
                })
            );
            return;

        }
        if(!mbData?.email){
            localStorage.removeItem("access");
            localStorage.removeItem("remail");
            localStorage.removeItem("page");
            localStorage.removeItem("sendData");
            dispatch(
                logOut()
            );
            dispatch(
                open({
                    component: <PopupMain
                        width={400}
                        text="로그인이 필요한 서비스입니다."
                        button="로그인"
                        buttonCancel="닫기"
                        func="login"
                        url={location.pathname}
                    />
                })
            );
        } else {

            api.postDataRefresh("/event/comment" ,{
                eventSeq:info.eventSeq,
                memberSeq:mbData.userSeq,
                memberName:mbData.name,
                reply:comment
            },mbData).then(res=>{
                if(res?.headers?.get("New-Access-Token")){
                    localStorage.setItem("access", res.headers.get("New-Access-Token"));
                }
                if(res && res?.data?.status=="SUCCESS"){
                    
                    let datas = res.data.data;
                    datas['registDate'] = today();
                  
                    setCommentList([...comment_list, res.data.data]);
                    setComment("");
                } else {
                    localStorage.removeItem("access");
                    localStorage.removeItem("remail");
                    localStorage.removeItem("page");
                    localStorage.removeItem("sendData");
                    dispatch(
                        logOut()
                    );
                    dispatch(
                        open({
                            component: <PopupMain
                                width={400}
                                text="로그인이 필요한 서비스입니다."
                                button="로그인"
                                buttonCancel="닫기"
                                func="login"
                                url={location.pathname}
                            />
                        })
                    );
                }
            });
        }
    }
    
    useEffect(() => {
        if(id > 0 ){
            api.getData("/event/"+id ,{
            }).then(res=>{
                if(res && res?.data?.status=="SUCCESS"){
                    setInfo(res.data.data);
                    dispatch(
                        setEtcData({
                            key: 'eventTitle',
                            value: res.data.data.title
                        })
                    ) 

                }
            });
            api.getData("/event/comment/"+id ,{
                seq:mbData.userSeq ? mbData.userSeq : 0
            }).then(res=>{ 
                if(res && res?.data?.status=="SUCCESS"){
                    setCommentList(res.data.data);
                }
            });            

        }
    }, [id]);

    function maskName(name) {
        if (name.length === 1) {
            return name;
        } else if (name.length === 2) {
            return name.slice(0, 1) + '*';
        } else {
            return name.slice(0, 1) + '*'.repeat(name.length - 2) + name.slice(-1)
        }
    }

    return (
        <div className="changbi event">
            <div className="wrap">

                <div className="board view">
                    <h1><small className={info?.ing===1 ? 'ing' : 'end'}>{info?.ing===1 ? '진행중' : '종료'}</small>{info?.title}</h1>   
                    <div className="info-editor" dangerouslySetInnerHTML={{__html: info?.contents}}></div>

                    {info?.replyStatus ?
                    <div className="comment">
                        <h2>이벤트 참여 댓글을 남겨주세요.</h2>
                        <input type="text" placeholder="내용을 입력하세요." value={comment} onChange={(e)=>setComment(e.target.value)}/>
                        <button type="button" className="btn-regist" onClick={()=>onCommentSave()}>등록</button>
                        <dl>
                            <dt>댓글 <strong>{comment_list.length}</strong></dt>
                            {comment_list.map(val=>(
                            <dd>
                                <span>{maskName(val.memberName)} · <small>{timeForToday(val.registDate?.substring(0,16))}</small></span>
                                <p className={val.view===0 ? 'lock' : ''}>{val.reply}</p>
                                {val.memberSeq === mbData.userSeq && 
                                    <button type="button" onClick={(e)=>onCommentRemove(val.eventReplySeq)}>삭제</button>
                                }
                            </dd>
                            ))}

                        </dl>
                    </div>
                    : <></>}
                    <div className="info-button">
                        <button type="button" onClick={()=>navigate("/event",{state:location?.state})}>목록</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
