import React, { useEffect, useState, useRef } from 'react';
import { Routes, useNavigate, useLocation, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { open, close } from '../../redux/popupSlice';
import { logIn, logOut  } from '../../redux/usersSlice';
import PopupMain from '../../pages/popup/PopupMain';
import * as api from '../../libs/api';
import Pagination from 'react-js-pagination';

export default function Notice() {
    const { id } = useParams();

    const [list, setList] = useState([]);
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(1);
    const [limit, setLimit] = useState(10);
    const [info, setInfo] = useState();
    const [prev, setPrev] = useState();
    const [next, setNext] = useState();        

    const location = useLocation(); 
    const navigate = useNavigate();
    const dispatch = useDispatch();
    

    const onList = (page) => {
        api.getData("/notice" ,{
            page:page-1,
            size:limit
        }).then(res=>{

            if(res && res?.data?.status=="SUCCESS"){
                setList(res.data.data.content);
                setPage(page-1);
                setTotal(res.data.data.totalElements);                   
            }
        });
    }
    useEffect(() => {
        if(id > 0 ){
            setInfo(location?.state);
            api.getData("/notice/"+id ,{}).then(res=>{
                if(res && res?.data?.status=="SUCCESS"){
                    setInfo(res.data.data.notice);
                    setPrev(res.data.data.prev);
                    setNext(res.data.data.next);
                }
            });
        }else{
            onList(1);
        }
    }, [id]);
    return (
        <div className="changbi">
            <div className="wrap">
                {!id  ?
                <div className="board notice">
                    <h1>공지사항</h1>
                    <table>
                        <colgroup>
                            <col width="*" />
                            <col width="100" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>제목</th>
                                <th>날짜</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.map((val) => (
                            <tr className={val.type==1 ? 'notice': ''}>
                                <td><Link to={"/notice/"+val.noticeSeq} state={val}>{val.title}</Link></td>
                                <td className="f14">{val.registDate?.substr(0,10).replaceAll("-",".")}</td>
                            </tr>
                            ))}
                        </tbody>
                    </table>

                    <div className="paging">
                        <Pagination
                            activePage={page+1}
                            itemsCountPerPage={limit}
                            totalItemsCount={total}
                            pageRangeDisplayed={10}
                            itemClassFirst="page_first"
                            itemClassPrev="page_prev"
                            itemClassNext="page_next"
                            itemClassLast="page_last"
                            onChange={onList}
                        />
                    </div>
                </div>
                :
                <div className="board view">
                    <h1>{info?.title}</h1>   
                    <div className="info-editor" dangerouslySetInnerHTML={{__html: info?.contents}}>
                        
                    </div>
                    <div className="preview">
                        <dl>
                            <dt>이전 글</dt><dd>
                                {prev?.noticeSeq > 0 ?
                                <Link to={"/notice/"+prev.noticeSeq} state={prev}>{prev?.title}</Link>
                                : <span className="nodata">이전 글이 없습니다.</span>
                                }
                            </dd>
                            <dt>다음 글</dt><dd>
                                {next?.noticeSeq > 0 ?
                                <Link to={"/notice/"+next.noticeSeq} state={next}>{next?.title}</Link>
                                : <span className="nodata">다음 글이 없습니다.</span>
                                }
                            </dd>
                        </dl>
                    </div>
                    <div className="info-button">
                        <button type="button" onClick={(()=>navigate(-1))}>목록</button>
                    </div>
                </div>
                }
            </div>
        </div>
    );
}
