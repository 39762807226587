import {createSlice} from '@reduxjs/toolkit';

const loadingSlice = createSlice({
  name: 'loading',
  initialState: {
    open: false,
  },
  reducers: {
    loadingStart(state) {
        state.open = true;
    },
    loadingEnd(state) {
        state.open = false;
    },
  },
});

export const {
    loadingStart,
    loadingEnd,
} = loadingSlice.actions;
export default loadingSlice.reducer;
