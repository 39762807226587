import React, { useEffect, useState, useRef } from 'react';
import { Routes, useNavigate, useLocation, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { open, close } from '../redux/popupSlice';
import { logIn, logOut  } from '../redux/usersSlice';
import PopupMain from '../pages/popup/PopupMain';
import View from "../components/view";
import List from "../components/list";
import Submenu from "../components/sub_menu";
import * as api from '../libs/api';

export default function Book(props) {
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [title, setTitle] = useState("한 학기 한 권 읽기"); 
    const [comment, setComment] = useState(); 
    const { category_seq, board_seq } = useParams();
    const location = useLocation();
    const [cseq, setCSeq] = useState(category_seq); 

    
    useEffect(() => {       
        
        if(category_seq > 0 ){
            setCSeq(category_seq);
        }else{
            api.getData("/category/top1" ,{
            }).then(res=>{ 

                if(res && res?.data?.status==='SUCCESS'){
                    setCSeq(res.data.data.categorySeq);
                    navigate("/book/list/"+res.data.data.categorySeq)
                }
            });
        }        
    },[cseq]);
    return (
        <div className="changbi">
            <div className="wrap">
                <div className="etc-menu">
                    <h1 className="m_title">수업 연계 도서</h1>
                    <Submenu setTitle={setTitle} type="1"/>
                </div>
                {location.pathname.indexOf("/book/list") >= 0 ?   
                    <List title={title} comment={comment} link={"/book/view/"+category_seq} category_seq={category_seq} type={1} />
                : location.pathname.indexOf("/book/view") >= 0 ?             
                    <View title={title} back={"/book/list/"+category_seq} state={location?.state} name={props?.name}/>
                :
                    <List title={title} comment={comment} link={"/book/view/"+category_seq} category_seq={category_seq} type={1}/>
                }
            </div>
        </div>
    );
}
