import React, { useEffect, useState, useRef, forwardRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Highlighter from "react-highlight-words";
import moment from 'moment';

import { logIn, logOut } from '../../redux/usersSlice';
import { open, close } from '../../redux/popupSlice';
import { loadingStart, loadingEnd } from '../../redux/loadingSlice';

export default function PopupRegister({ title, message, id, onPress, onPressText, closeBtn, closeText }) {

    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(close());
    };

    return (
        <>
            <div className="popup_register">
                <div className="popup_title_section register">
                    <p className="popup_title">{title}</p>
                    <button 
                        type="button" 
                        className="exit_btn"
                        onClick={() => {
                            handleClose();
                        }}>
                    </button>
                </div>

                <div className="popup_register_comment_box">
                    <p className="popup_message">{message}</p>
                    <p className="popup_message id">{id}</p>
                    <div className="popup_btn_box_custom">
                        { closeBtn &&
                        <button onClick={closeBtn} className="popup_close">{closeText}</button>
                        }
                        <button onClick={onPress} className="popup_press">{onPressText}</button>
                    </div>
                </div>

               {/* <button onClick={() => dispatch(close())}>닫기</button> */}
               
            </div>
        </>
    );
}
