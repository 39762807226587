import React, { useEffect, useState, useRef } from 'react';
import { Routes, useNavigate, useLocation, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import images from "../libs/images";
import View from "../components/view";
import List from "../components/list";
import Banner from "../components/banner";
import Submenu from "../components/sub_menu";

export default function Teacher(props) {
    const [title, setTitle] = useState(); 
    const [comment, setComment] = useState(); 
    const { category_seq, board_seq } = useParams();
    const location = useLocation();



    return (
        <div className="changbi">
            <div className="wrap">
                <div className="etc-menu">
                    <h1 className="m_title">선생님 맞춤 자료</h1>
                    <Submenu setTitle={setTitle} type="2"/>
                </div>      

 
                {location.pathname.indexOf("/teacher/list") >= 0 ?         
                    <List title={title} comment={comment} link={"/teacher/view/"+category_seq} category_seq={category_seq} type={2}/>
                : location.pathname.indexOf("/teacher/view") >= 0 ?             
                    <View title={title} back={"/teacher/list/"+category_seq} state={location?.state} name={props?.name}/>
                :
                    <Banner type="2" />
                }
            </div>
        </div>
    );
}
