import React, { useEffect, useState, useRef, forwardRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import images from "../../libs/images";

import routes from "../../libs/routes";

import BtnScrap from '../../components/btn_scrap';
import BtnFileDownload from '../../components/file_download';
import * as api from '../../libs/api';

import { logIn, logOut } from '../../redux/usersSlice';
import { open, close } from '../../redux/popupSlice';
import { loadingStart, loadingEnd } from '../../redux/loadingSlice';


export default function PopupUnitData(props) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [list, setList] = useState([]);

    useEffect(() => {
        api.getData("/curriculum/unit" ,{
            subjectSeq:props.subjectInfo.subjectSeq
        }).then(res=>{
            if(res && res?.data?.status=="SUCCESS"){
                setList(res.data.data);
            }
        });
    }, []);
    return (
        <>
            <div className="changbi pop _03">
                <h1>
                    단원별 자료
                    <button type="button" onClick={() => dispatch(close())}><img src='/images/exit-icon.svg' /></button>
                </h1>
                <div className="form">
                    <div className="list">
                        <dl>
                            <dt>단원명</dt>
                        </dl>
                        <dl className="scroll">
                            {list.map((val) => (
                            <dd>
                                <strong>{val.name}</strong>
                                <div>
                                    {/*<BtnScrap info={val} type="2"/>*/}
                                    <BtnFileDownload type="99" fileName={val.name} unitSeq={val.unitSeq} subectSeq={props.subjectInfo.subjectSeq}/>
                                </div>
                            </dd>
                            ))}

                        </dl>
                    </div>
                </div>
            </div>
        </>
    );
}
