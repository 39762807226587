import React, { useEffect, useState, useRef } from 'react';
import { Routes, useNavigate, useLocation, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { open, close } from '../redux/popupSlice';
import { logIn, logOut  } from '../redux/usersSlice';
import PopupMain from '../pages/popup/PopupMain';
import * as api from '../libs/api';
import { imageUrl } from "../libs/utils";
import { useCookies } from 'react-cookie';

export default function Banner(props) {
    const [cookies, setCookie, removeCookie] = useCookies(["access"]);

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    function goUrl(url) {
        navigate(url);
    }

    const [banner, setBanner] = useState();
    useEffect(() => {
        
        api.getData("/banner/"+props.type, {}).then(res=>{
 
            if(res && res?.data?.status=="SUCCESS"){
                setBanner(res.data.data)
            }
        });
        
    }, [props.type]);

    return (
        <div className="box inno-banner">
            <div>
                <Link to={banner?.url1}><img src={imageUrl+"banner?file="+banner?.banner1} width="100%"/></Link>
                <Link to={banner?.url2}><img src={imageUrl+"banner?file="+banner?.banner2} width="100%"/></Link>
            </div>
            <div>
                <Link to={banner?.url3}><img src={imageUrl+"banner?file="+banner?.banner3} width="100%"/></Link>
                <Link to={banner?.url4}><img src={imageUrl+"banner?file="+banner?.banner4} width="100%"/></Link>
                <Link to={banner?.url5}><img src={imageUrl+"banner?file="+banner?.banner5} width="100%"/></Link>
            </div>
        </div>
    );
}
