import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router';
import { Routes, useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { open, close } from '../redux/popupSlice';
import { logIn, logOut  } from '../redux/usersSlice';
import PopupMain from '../pages/popup/PopupMain';
import images from "../libs/images";
import * as api from '../libs/api';
import BtnScrap from "../components/btn_scrap";
import BtnFileDownload from '../components/file_download';

export default function View(props) {
    const { mbData, accessToken, autoLogin } = useSelector(s => s.usersReducer);
    
    const params = useParams();
    //const { category_seq, board_seq } = props;

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const [info, setInfo] = useState();
    const [prev, setPrev] = useState();
    const [next, setNext] = useState();
    const [link, setLink] = useState();
    function goUrl(url) {
        navigate(url);
    }

    const FileDownload = (e) => {

        api.blobData("/download" ,{
            filename:info.file,
            folder:"board"
        },accessToken).then(res=>{
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
                "download",
                `${info.oriFile}`
            );
            document.body.appendChild(link);
            link.click();
    
            link.parentNode.removeChild(link);
        });
    }
    const goBack = () =>{
        if(location?.state?.link) {
            navigate(location?.state?.link,{state:location?.state?.page})
        }else{
            const link = location.pathname.split("/");
            navigate("/"+link[1]+"/list/"+link[3]);
        }
    }
    useEffect(() => {

        const path = location.pathname.split("/");
        setLink(path[1]);
        let link = "/board/"+params.category_seq+"/"+params.board_seq;
        if(props?.name === "preview"){
            link = "/preview/board/"+params.category_seq+"/"+params.board_seq;
        }
        api.getData(link ,{
        }).then(res=>{

            if(res && res?.data?.status=="SUCCESS"){
                res.data.data.board['category'] = title;
                setInfo(res.data.data.board);
                setPrev(res.data.data.prev);
                setNext(res.data.data.next);
            }
        });

    }, [params.category_seq,params.board_seq]);

    const { title, back} = props;
    return (
        <div className="box m-view">
            <h1 className="title">{title}</h1>
            <div className="view">
                <h2 className="sub">
                    {info?.title}
   
                    
                    {mbData?.email  &&
                    <div>
                        <BtnScrap info={info} type="4" />
                        {info?.file ?
                        <BtnFileDownload folder="board" filename={info?.file} filename_original={info?.oriFile} text={params.category_seq == 3 ? '독서 활동 지원 자료' : '다운로드'} />
                        :
                        <></>
                        }


                    </div>
                    }
                </h2>
                <div className="sun-editor-editable" dangerouslySetInnerHTML={{__html: info?.contents}}></div>

                
                <div className="preview">
                    <dl>
                    <dt>이전 글</dt>
                    <dd>
                        {prev?.boardSeq > 0 ?
                        <Link to={"/"+link+"/view/"+prev.categorySeq+"/"+prev?.boardSeq} state={prev}>{prev?.title ? prev?.title : '제목이 없는 글입니다.'}</Link>
                        : <span className="nodata">이전 글이 없습니다.</span>
                        }
                    </dd>
                    <dt>다음 글</dt><dd>
                        {next?.boardSeq > 0 ?
                        <Link to={"/"+link+"/view/"+next.categorySeq+"/"+next?.boardSeq} state={next}>{next?.title ? next?.title : '제목이 없는 글입니다.'}</Link>
                        : <span className="nodata">다음 글이 없습니다.</span>
                        }
                    </dd>
                    </dl>
                </div>
                <div className="info-button">
                    <button type="button" onClick={()=>goBack()}>목록</button>
                </div>
            </div>
        </div>

    );
}
