import React, { useEffect, useState, useRef } from 'react';
import { Routes, useNavigate, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { open, close } from '../redux/popupSlice';
import { logIn, logOut, setData as setUserData  } from '../redux/usersSlice';
import images from "../libs/images";
import PopupDownload from './popup/PopupDownload';
import PopupCategoryData from './popup/PopupCategoryData';
import PopupUnitData from './popup/PopupUnitData';
import PopupMain from './popup/PopupMain';
import * as api from '../libs/api';
import { imageUrl } from "../libs/utils";
import BtnScrap from '../components/btn_scrap';
import BtnBook from '../components/btn_book';
import BtnFileDownload from '../components/file_download';
import BtnScrapAll from "../components/btn_scrap_all";
import BtnFileDownloadAll from "../components/file_download_all";
import Pagination from 'react-js-pagination';

export default function CurriculumPage(props) {

    const { type, id } = useParams();
    const { mbData, mbBook } = useSelector(s => s.usersReducer);

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [isCommon, setCommon]  = useState(false);
    const [left_small, setLeftSmall]  = useState(0);

    const [list, setList] = useState([]);
    const [ori, setOri] = useState([]);
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(1);
    const [limit, setLimit] = useState(10);
    
    //과목
    const [subject, setSubject]  = useState();
    const [unit_list, setUnitList]  = useState();
    const [unit_level_list, setUnitLevelList]  = useState();
    const [type_list, setTypeList]  = useState();
    const [type_level_list, setTypeLevelList]  = useState();
    const [year_list, setYearList]  = useState();
    
    let [left_menu, setLeftMenu] = useState(0);
    let [left_menu_onoff, setLeftMenuOnOff] = useState(false);
    let [left_smenu, setLeftSMenu] = useState(0);
    let [tab, setTab] = useState(0);
    let [sub_tab, setSubTab] = useState(0);
    let [url, setUrl] = useState("curriculum");
    const [tab_count, setTabCount] = useState([0,0,0,0]);
    const [checkItems, setCheckItems] = useState([]);
    const [view_mybook, setViewMybook] = useState([]);
    const [view_ex, setViewEx] = useState(true);

    
    const [check_update, setChkUpdate] = useState(true);

    
    function goUrl(url) {
        navigate(url);
    }

    //단원
    const onLeftMenu = (val) => {
        setUrl("curriculum");
        setLeftMenu(val.unitSeq)
        setCommon(false);
        onList(1,tab,0,val.unitSeq,0,"curriculum");
        setLeftSMenu(0);    
        setSubTab(0);
    }
    //소단원
    const onLeftSMenu = (val,val2) => {
        
        setCommon(false);
        setLeftMenu(val.unitSeq);
        setLeftSMenu(val2.unitLevelSeq);
        onList(1,tab,0,val.unitSeq,val2.unitLevelSeq,"curriculum");
    
        setSubTab(0);
    }
    
    const onLeftMobile = (e) => {
        setCommon(false);
        const ss = e.target.value.split("|");
        if(ss[0]==='B'){
            setLeftMenu(ss[1])
            onList(1,tab,0,ss[1],0,"curriculum");
            setLeftSMenu(0);
            setSubTab(0);
        }
        if(ss[0]==='S'){
            setLeftMenu(ss[1]);
            setLeftSMenu(ss[2]);
            onList(1,tab,0,ss[1],ss[2],"curriculum");
            setSubTab(0);
        }
    }
    //탭 4개
    const onTab = (val) => {
        setTab(val.typeSeq);
        sub_tab=0;
        setSubTab(0);
        onList(1,val.typeSeq,sub_tab,left_menu,left_smenu,"curriculum");
    }
    //탭 하단 카테고리
    const onSubTab = (val) => {
        if(val === 0){
            setSubTab(0);
            onList(1,tab,0,left_menu,left_smenu,"curriculum");
        }else{
            setSubTab(val.typeLevelSeq);
            onList(1,tab,val.typeLevelSeq,left_menu,left_smenu,"curriculum");
        }
    
    }


    //단원 공통 자료
    const onLeftCommon = (com) => {
        //setTab(0);
        //setSubTab(0);
        //setLeftMenu(0);
        //setLeftSMenu(0);
        setLeftMenu(0)
        setUrl("curriculum/common");
        onList(1,0,com,0,0,"curriculum/common");
        //단원별 공통 자료
        setCommon(true);
        setLeftSmall(com);
     
    }

    const onLeftCommonM = (com) => {
        

     
    }

    //const onList = (page,typeSeq,unitSeq,subjectInfo) => {
    const onList = (page,t,tt,l,ll,u) => {       
  

        if(t>=0){tab = t;}
        if(tt>=0){sub_tab = tt;}
        if(l>=0){left_menu = l;}
        if(ll>=0){left_smenu = ll;}
        if(u){url = u;}

        let parm;
        if(url == "curriculum"){
            parm = {
                page:page-1,
                size:limit,
                unitSeq:left_menu,
                unitLevelSeq:left_smenu,
                typeSeq:tab,
                typeLevelSeq:sub_tab,
                subjectSeq:id
            };
        }
        if(url == "curriculum/common"){
            parm = {
                page:page-1,
                size:limit,
                subjectSeq:id,
                typeLevelSeq:sub_tab,           
                type:1
            };
        }

        api.getData("/"+url ,parm).then(res=>{
            
            if(res && res?.data?.status=="SUCCESS"){
                const ret = res.data.data
                setList(ret.curriculumList.content);
                setPage(page-1);
                setTotal(ret.curriculumList.totalElements);           
                setTabCount([ret.curriculumType1,ret.curriculumType2,ret.curriculumType3,ret.curriculumType4]);
            }
        });
  

    }

    useEffect(() => {
        setViewEx(true);
        api.getData("/curriculum/subject/"+id).then(res=>{
            if(res?.headers?.get("New-Access-Token")){
                localStorage.setItem("access", res.headers.get("New-Access-Token"));
            }
            if(res && res?.data?.status==='SUCCESS'){
                const ret = res.data.data;
                ret.curriculumSubject['year'] = ret.curriculumYear?.find(val=>val.seq===ret.curriculumSubject?.yearSeq).year;

                if(ret.curriculumSubject?.name?.indexOf("업데이트 중") >= 0 ){
                    setChkUpdate(false);
                }else{
                    setChkUpdate(true);
                }

                setSubject(ret.curriculumSubject);
                setUnitList(ret.curriculumUnit);
                setUnitLevelList(ret.curriculumUnitLevel);
                setTypeList(ret.curriculumType); 
                setTypeLevelList(ret.curriculumTypeLevel);
                setYearList(ret.curriculumYear);
 
                
                //제일 처음 수업자료 탭
                const left = (ret.curriculumUnit[0] && ret.curriculumUnit[0]?.unitSeq ? ret.curriculumUnit[0]?.unitSeq : 0)
                setLeftMenu( left );
                setLeftSMenu(0);
                const stab = (ret.curriculumType[0] && ret.curriculumType[0]?.typeSeq ? ret.curriculumType[0]?.typeSeq : 0);
                setTab( stab );
                setSubTab(0);
              
                if(props.quick_mybook?.length > 0 ){      

                }else{
                    if(mbData?.userSeq > 0){

                        const use = props?.local_mybook.find(val=>val.img === ret?.curriculumSubject?.image)?.img;
                        if(!use){
                            props?.setLocalMyBook([{img:ret?.curriculumSubject?.image,url:location.pathname}, ...props.local_mybook]);
                        }       
                    }
                }

                if(ret.curriculumSubject?.school == "인정" || ret.curriculumSubject?.name?.indexOf("업데이트 중") >= 0){
                    onLeftCommon(0)
                } else {
                    onList(1,stab,0,left,0,"curriculum");
                }

                api.getData("/curriculum/common" ,{
                    page:0,
                    size:1,
                    subjectSeq:ret.curriculumSubject.subjectSeq,
                    typeLevelSeq:0,
                    type:4
                }).then(resex=>{
                    console.log(resex.data.data.curriculumList.totalElements);
                    if(resex.data.data.curriculumList.totalElements <= 0){
                        setViewEx(false);
                    }
                });
            }
        });
        props?.setCurrMenu(type);



    }, [type, id]);
    //}, []);    

    const onFileDownload = (type) => {
        dispatch(
            open({
                component: <PopupDownload subjectInfo={subject} type={type} unitSeq={left_menu} unitLevelSeq={left_smenu} typeSeq={tab} typeLevelSeq={sub_tab} />
                
            })
        );
    }


    const onUnitDownload = () => {
        dispatch(
            open({
                component: <PopupUnitData subjectInfo={subject} unitSeq={left_menu} unitLevelSeq={left_smenu} typeSeq={tab} typeLevelSeq={sub_tab}   />
            })
        );
    }
    const onCateDownload = () => {
        dispatch(
            open({
                component: <PopupCategoryData  subjectInfo={subject} typeList={type_list} unitSeq={left_menu} unitLevelSeq={left_smenu} typeSeq={tab} typeLevelSeq={sub_tab} />
                
            })
        );
    }
    const onAllChecked = (e) => {

        if(e.target.checked){
            const idArray = [];
            list.forEach((el) => idArray.push(el.curriculumSeq));
            setCheckItems(idArray);
        } else {
            setCheckItems([]);
        }

        setList(
            list.map(par=>
               par.curriculumSeq > 0 ? { ...par, checked: e.target.checked } : par
            )
        )
    }

    const onChecked = (e,val) => {
        if( e.target.checked ){
            setCheckItems(prev => [...prev, val.curriculumSeq]);
        }else{
            setCheckItems(checkItems.filter((el) => el !== val.curriculumSeq));
        }        
        setList(
            list.map(par=>
                par.curriculumSeq === val.curriculumSeq ? { ...par, checked: !val.checked } : par
            )
        )
    }

    const onMyBook = (info) => {
        const parm = {
            memberSeq:mbData.userSeq,
            subjectSeq:info.subjectSeq,
            school:info.school,
            subject:info.subject,
            yearSeq:info.yearSeq,
            year:info.year,
            subjectName:info.name,
            subjectThumbnail:info.image
        };
        
        
        const newMbBook = mbBook.find(val => val.subjectSeq === info.subjectSeq)?.subjectSeq;
        if(newMbBook > 0 ){

        }else{
            dispatch(
                setUserData({
                    key: 'mbBook',
                    value: [parm, ...mbBook]
                })
            )
        }
        

        //props?.setQuickMyBook([parm, ...props.quick_mybook]);
    

        api.postDataRefresh("/book" ,parm,mbData).then(res=>{ 
            if(res && res.code === "ERR_BAD_REQUEST"){
                dispatch(
                    open({
                        component: <PopupMain
                            func="login"
                            width={400}
                            text="로그인이 필요한 서비스입니다."
                            button="로그인"
                            buttonCancel="닫기"
                            url={location.pathname}
                        />,
                        
                    })
                );
            }
            if(res && res?.data?.status=="SUCCESS"){
                if(res.data.data.seq){
                    dispatch(
                        open({
                            component: <PopupMain
                                width={400}
                                text={"내 교과서에 추가되었습니다."}
                                button="닫기"
                            />,
                        })
                    );
                }
            } else{
                dispatch(
                    open({
                        component: <PopupMain
                            width={400}
                            text="추가된 교과서입니다."
                            button="닫기"
                        />,
                        
                    })
                );
            }
        });
    }
    
    const tailName = (filename) => {
        const tail = filename.split(".");
        return tail[tail.length-1].trim();
    }
    const onEbook = (msg) => {
        if(msg){
            dispatch(
                open({
                    component: <PopupMain
                        func="teacher"
                        width={400}
                        text={"선생님 인증이 필요합니다.\n\n인증 후에도 안되시면 재로그인 후 시도해 주세요."}
                        button="인증하기"
                        buttonCancel="닫기"
                        url={location.pathname}
                    />,
                    
                })
            );
        } else{
            dispatch(
                open({
                    component: <PopupMain
                        func="login"
                        width={400}
                        text="로그인이 필요한 서비스입니다."
                        button="로그인"
                        buttonCancel="닫기"
                        url={location.pathname}
                    />,
                    
                })
            );
        }
        
    }
    return (
        <div className="changbi">
            <div className="course">
                <div className="course-top">
                    <div className="course-title">
                        {subject?.image ? 
                        <img src={imageUrl + "subject?file="+encodeURIComponent(subject?.image)} className="book_img" width="200" height="200"/>
                        :
                        <img src="/images/no_book_image.svg" className="book_img" width="200" height="200"/>
                        }
                        <p>
                            <span>{subject?.school} | {year_list?.map(val=>val.seq===subject?.yearSeq && val.year)}년 개정</span>
                            <h1>{subject?.name}</h1>

                            <button type="button" className="my" onClick={(e)=>onMyBook(subject)}><img src='/images/add-line.svg' />내 교과서</button>
                            
                        </p>
                        <div className="view">
                            {mbData?.userSeq ? 
                                mbData?.grade ? 
                                    (subject?.viewEbook ? <a href={subject?.urlEbook} target="_blank"><img src="/images/icon-ebook.svg" />E-Book 보기</a> : <></>)
                                :
                                    (subject?.viewEbook ? <a onClick={(e)=>onEbook(1)} target="_blank"><img src="/images/icon-ebook.svg" />E-Book 보기</a> : <></>)
                            :
                                (subject?.viewEbook ? <a onClick={(e)=>onEbook(0)}><img src="/images/icon-ebook.svg" />E-Book 보기</a> : <></>)
                            }


                            {subject?.viewDvd ? <BtnFileDownload folder="dvd" type="1" filename={subject?.fileDvd} filename_original={subject?.fileDvdOri} /> : <></>}
                            {view_ex || check_update ? <button type="button" onClick={()=>onFileDownload(4)} ><img src='/images/icon-test.svg'/>중간/기말 평가</button> : <></>}
                        </div>
                    </div>
                    {check_update ? 
                    <div className="course-button">
                        {subject?.viewData ? <BtnFileDownload folder="once" type="2" filename={subject?.fileData} filename_original={subject?.fileDataOri} /> : <></>}
                        <button type="button" onClick={()=>onFileDownload(2)}><img src='/images/icon_search.svg' /> 교과서</button>
                        <button type="button" onClick={()=>onFileDownload(3)}><img src='/images/icon_search.svg' /> 지도서</button>
                        <button type="button" onClick={()=>onUnitDownload()}><img src='/images/icon_search.svg' /> 단원별</button>
                        <button type="button" onClick={()=>onCateDownload()}><img src='/images/icon_search.svg' /> 유형별</button>
                    </div>
                    :<></>
                    }
                </div>
            </div>
            <div className="course-detail">
                <div className="course-menu">
                    
                    {subject?.school != '인정' ?  
                    
                    (check_update || unit_list?.length > 0) ? 
                        <>
                        <dl>
                            <dt>단원별 자료</dt>
                            {unit_list && unit_list?.map((val) => (
                                <dd className={left_menu_onoff ? 'active' : ''} >
                                    <span onClick={(e)=>onLeftMenu(val)}>{val.name}</span>
                                    <ul className={val.unitSeq === left_menu ? 'show' : 'hide'}>
                                    
                                        {unit_level_list?.map((val2) => (
                                            val.unitSeq === val2.unitSeq && (<li onClick={(e)=>onLeftSMenu(val,val2)} className={val2.unitSeq === left_menu && val2.unitLevelSeq === left_smenu ? 'active' : ''} >{val2.name}</li>)
                                        ))}
                                    </ul>
                                </dd>
                            ))}
                        </dl>


                        <select onChange={(e)=>onLeftMobile(e)}>
                            {unit_list && unit_list?.map((val) => (
                                <>
                                    <option value={"B|"+val.unitSeq}>{val.name}</option>
                                    {unit_level_list?.map((val2) => (
                                        val.unitSeq === val2.unitSeq && (<option value={"S|"+val.unitSeq+"|"+val2.unitLevelSeq}  >&nbsp;&nbsp;&nbsp;&nbsp;{val2.name}</option>)
                                    ))}                      
                                </>
                            ))}
                        </select>
                        

                        <select onChange={(e)=>onLeftCommon(e.target.value)} onClick={(e)=>e.target.value=""} value={left_small}>
                            <option value="0">단원 공통 자료</option>
                            <option value="1">교과서</option>
                            <option value="2">교사용 교과서</option>
                            <option value="3">지도서</option>
                        </select>
                        </>
                    :   
                    <></>
                    
                    :
                    <></>
                    }
                    <dl  className="course-common">
                        <dt onClick={(e)=>onLeftCommon(0)}>단원 공통 자료</dt>
                        {check_update ? 
                        <>
                        <dd className={left_small==1 ? 'active' : ''} onClick={(e)=>onLeftCommon(1)}><span>교과서</span></dd>
                        <dd className={left_small==2 ? 'active' : ''} onClick={(e)=>onLeftCommon(2)}><span>교사용 교과서</span></dd>
                        <dd className={left_small==3 ? 'active' : ''} onClick={(e)=>onLeftCommon(3)}><span>지도서</span></dd>
                        </>
                        :
                        <></>
                        }
                    </dl>
                </div>
                <div className="course-tab">
                    <h2>{isCommon  ? '단원 공통 자료' : '단원별 자료'}</h2>
                    {isCommon ? '' :
                    <>
                        <div className="tab">
                            {type_list && type_list?.map((val,index) => (
                                <button type="button" onClick={(e)=>onTab(val)} className={val.typeSeq === tab ? 'active' : ''}  >{val.name} <strong>{tab_count[index]}</strong></button>
                            ))}
                        </div>
                        
                        <div className="course-tag">
                            <ul>
                                <li className={!sub_tab ? 'active' : '' } onClick={(e)=>onSubTab(0)} >전체</li>
                                {type_level_list?.map((val2) => (
                                    tab === val2.typeSeq && 
                                    (<li onClick={(e)=>onSubTab(val2)} className={val2.typeLevelSeq === sub_tab ? 'active' : ''}>{val2.name}</li>)
                                ))}
                            </ul>
                        </div>
                    </>
                    }
                    <div className="list">
                        <dl>
                            <dt>
                                <label className="check"><input type="checkbox" onClick={(e)=>onAllChecked(e)} checked={checkItems.length === list.length ? true : false}/><span>전체 선택</span></label>
                                <BtnScrapAll list={list} type="2" />
                                <BtnFileDownloadAll folder="data" list={list} name="file" ori="fileOri"/>
                            </dt>
                            {list && list?.map((val)=>(
                            <dd>
                                <label className="check"><input type="checkbox" checked={checkItems.includes(val.curriculumSeq) ? true : false} onClick={(e)=>onChecked(e,val)}/></label>

                                {val?.fileOri ? <img src={'/images/'+tailName(val?.fileOri)+'.svg'} /> : <></> }
                                
                                {/*<span>{val.name}</span>*/}
                                <BtnFileDownload folder="data" filename={val?.file} filename_original={val?.fileOri} text={val.name} type="5"/>
                                <div className="curr">                               
                                    {val.file?.indexOf(".pdf")>=0 ? <button type="button" ><a href={api.url+"/pdf?folder=data&file="+encodeURIComponent(val.file?.trimEnd())} target="_blank"><img src='/images/button_search.svg' /></a></button> : ''}
                                    <BtnScrap info={val} type="2" />
                                    {val?.file ? <BtnFileDownload folder="data" filename={val?.file?.trimEnd()} filename_original={val?.fileOri?.trimEnd()} /> : <></>}
                                </div>
                            </dd>
                            ))}
                        </dl>
                    </div>
                    {list.length > 0 ?
                    <div className="paging">
                        <Pagination
                            activePage={page+1}
                            itemsCountPerPage={limit}
                            totalItemsCount={total}
                            pageRangeDisplayed={10}
                            itemClassFirst="page_first"
                            itemClassPrev="page_prev"
                            itemClassNext="page_next"
                            itemClassLast="page_last"
                            onChange={onList}
                        />
                    </div>
                    :
                    <p className="no-data">내용이 없습니다.</p>
                    }
                </div>
            </div>
        </div>
    );
}
