import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import images from '../../libs/images';
import routes from '../../libs/routes';

import { regEmail,regPassword, autoHypenPhone } from '../../libs/utils'


import PopupMain from '../popup/PopupMain';
import PopupRegister from '../popup/PopupRegister'
import { useDispatch, useSelector } from 'react-redux';
import { open, close } from '../../redux/popupSlice';
import * as api from '../../libs/api';


export default function Check(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation(); 
    // 유효성 검사하는 부분
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    // -- 이메일 관련 -- //
    const [email, setEmail] = useState('');
    const emailRef = useRef(null);
    const [isEmailFocused, setIsEmailFocused] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(0);
    const [emailMessage, setEmailMessage] = useState("");

    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);
        if (regEmail.test(newEmail)) {
            setIsEmailValid(1);
            setEmailMessage("");
        } else {
            setIsEmailValid(0);
            setEmailMessage("이메일의 형식이 올바르지 않습니다.");
        }
    };

    const handleEmailFocus = () => {
        setIsEmailFocused(true);
        setIsPasswordFocused(false);
        setIsPasswordCheckFocused(false);
    };

    const handleEmailBlur = () => {
        if (email === '') {
            setIsEmailFocused(false);
        }
    };

    const [auth, setAuth] = useState();

    // -- 비밀번호 관련 -- //
    const [password, setPassword] = useState('');
    const passwordRef = useRef(null);
    const [isPasswordFocused, setIsPasswordFocused] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(0);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [passwordMessage, setPasswordMessage] = useState("");

    const [passwordCheck, setPasswordCheck] = useState('');
    const passwordCheckRef = useRef(null);
    const [isPasswordCheckFocused, setIsPasswordCheckFocused] = useState(false);
    const [isPasswordCheckValid, setIsPasswordCheckValid] = useState(0);
    const [isPasswordCheckVisible, setIsPasswordCheckVisible] = useState(false);
    const [passwordCheckMessage, setPasswordCheckMessage] = useState("");

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(prevState => !prevState);
    };

    const togglePasswordCheckVisibility = () => {
        setIsPasswordCheckVisible(prevState => !prevState);
    };

    const getInputType = (isPassword) => {
        if (isPassword) {
            return isPasswordVisible ? 'text' : 'password'; // 비밀번호 입력 필드
        } else {
            return isPasswordCheckVisible ? 'text' : 'password'; // 비밀번호 확인 입력 필드
        }
    };

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        if (regPassword.test(newPassword)) {
            setIsPasswordValid(1);
            setPasswordMessage("");
        } else {
            setIsPasswordValid(0);
            setPasswordMessage("8자리 이상 영문, 숫자, 특수문자(!@#$%^*+=-)로 입력하세요.");
        }
    };

    const handlePasswordCheckChange = (e) => {
        const newPasswordCheck = e.target.value;
        setPasswordCheck(newPasswordCheck);
        if (newPasswordCheck === password) {
            setIsPasswordCheckValid(1);
            setPasswordCheckMessage("");
        } else {
            setIsPasswordCheckValid(0);
            setPasswordCheckMessage("비밀번호가 일치하지 않습니다.")
        }
    };

    const handlePasswordFocus = () => {
        setIsPasswordFocused(true);
        setIsEmailFocused(false);
        setIsPasswordCheckFocused(false);
    };

    const handlePasswordBlur = () => {
        if (password === '') {
            setIsPasswordFocused(false);
        }
    };

    const handlePasswordCheckFocus = () => {
        setIsPasswordCheckFocused(true);
        setIsEmailFocused(false);
        setIsPasswordFocused(false);
    };

    const handlePasswordCheckBlur = () => {
        if (passwordCheck === '') {
            setIsPasswordCheckFocused(false);
        }
    };

    useEffect(() => {


        if (isPasswordFocused && passwordRef.current) {
            passwordRef.current.focus();
        } else if (isPasswordCheckFocused && passwordCheckRef.current) {
            passwordCheckRef.current.focus();
        }

        const auths = localStorage.getItem("sendData") ? JSON.parse(localStorage.getItem("sendData")) : '';
        if(auths){
            auths.TEL_NO = autoHypenPhone(auths?.TEL_NO);
        }
        
        setAuth(auths);

        

    }, [isEmailFocused, isPasswordFocused, isPasswordCheckFocused]);


    // 통과 인풋
    const isFormValid = isPasswordValid === 1 && isPasswordCheckValid === 1;

    function goUrl(url) {
        navigate(url);
        window.scrollTo(0,0);
    }

    // 팝업

    const RegisterPopup = (type) => {
        localStorage.setItem("sendData","");
        if(type === 'logincheck') {
            dispatch(
                open({
                    component: <PopupRegister
                        title=""
                        message={"비밀번호 변경에 실패했습니다.\n\n관리자에게 문의하세요."}
                        onPress={() => {
                            navigate(routes.login);
                            dispatch(close());
                        }}
                        onPressText="로그인"
                        />
                })
            )
        }
        if(type === 'ok') {
            navigate("/login");
            dispatch(
                open({
                    component: <PopupRegister
                        title=""
                        message={"비밀번호를 새로 설정했습니다.\n\n로그인 후 이용해주세요."}
                        id=""
                        onPress={() => {
                            navigate(routes.login);
                            dispatch(close());
                        }}
                        onPressText="확인"
                        />
                })
            )
        }
        if(type === 'confirm') {
            dispatch(
                open({
                    component: <PopupRegister
                        title=""
                        message={"해당 자료는 선생님 회원만\n이용하실 수 있습니다.\n마이 페이지에서 선생님 인증을 해주세요."}
                        id=""
                        onPress={() => {
                            window.location.reload();
                        }}
                        onPressText="확인"
                        />
                })
            )
        }
    };

    return (
        <div className="login_back">
            <div className="login_box">
                <div className="title_box register">
                    <button type="button" onClick={()=>goUrl("/")} className="m_close" ><img src='/images/m_close.svg' /></button>  
                    <p className="title">새로운 비밀번호를 입력해주세요.</p>
                </div>

                <div className="register_info_box">
                    <div className="mb_info">
                        <p className="name">{auth?.RSLT_NAME}<span className="gender">({auth?.RSLT_SEX_CD==='M' ? '남' : '여'})</span></p>
                        <p className="number">{auth?.TEL_NO}</p>
                    </div>

                    <div className="register_input_box">
  

                        {!isPasswordFocused ? (
                            <input
                                type="password"
                                placeholder="비밀번호"
                                value={password}
                                onChange={handlePasswordChange}
                                onFocus={handlePasswordFocus}
                            />
                        ) : (
                            <div className="input_on">
                                <input
                                    type={getInputType(true)}
                                    placeholder="비밀번호"
                                    value={password}
                                    onChange={handlePasswordChange}
                                    onBlur={handlePasswordBlur}
                                    ref={passwordRef}
                                />
                                <p className="input_name">비밀번호<span>*</span></p>
                                { password !== '' &&
                                    <img src={!isPasswordVisible ? images.input_view : images.input_view_off} className={`input_view_img ${isPasswordValid === 1 ? 'move' : ''} ${isPasswordValid === 0 ? 'set' : ''}`} onClick={togglePasswordVisibility} />
                                }
                                {isPasswordValid === 1 &&
                                    <img src='/images/input_check.svg' className="input_check_img" />
                                }
                                <p className={`err_message ${isPasswordValid === 0 ? 'on' : ''}`}>{passwordMessage}</p>
                            </div>
                        )}

                        {!isPasswordCheckFocused ? (
                            <input
                                type="password"
                                placeholder="비밀번호 재입력"
                                value={passwordCheck}
                                onChange={handlePasswordCheckChange}
                                onFocus={handlePasswordCheckFocus}
                            />
                        ) : (
                            <div className="input_on">
                                <input
                                    type={getInputType(false)}
                                    placeholder="비밀번호 재입력"
                                    value={passwordCheck}
                                    onChange={handlePasswordCheckChange}
                                    onBlur={handlePasswordCheckBlur}
                                    ref={passwordCheckRef}
                                />
                                <p className="input_name">비밀번호 재입력<span>*</span></p>
                                { passwordCheck !== '' &&
                                    <img src={!isPasswordCheckVisible ? images.input_view : images.input_view_off} className={`input_view_img ${isPasswordCheckValid === 1 ? 'move' : ''} ${isPasswordCheckValid === 0 ? 'set' : ''}`} onClick={togglePasswordCheckVisibility} />
                                }
                                {isPasswordCheckValid === 1 &&
                                    <img src='/images/input_check.svg' className="input_check_img" />
                                }
                                <p className={`err_message ${isPasswordCheckValid === 0 ? 'on' : ''}`}>{passwordCheckMessage}</p>
                            </div>
                        )}
                    </div>


                    
                    <button
                        type="button"
                        className={`login_btn none ${isFormValid ? 'active' : ''}`}
                        onClick={() => {

                            if (isFormValid) {
                                const dd = new Date();
                                const today = (dd.getFullYear()+"-"+("0" + (dd.getMonth() + 1)).slice(-2)+"-"+("0" + dd.getDate()).slice(-2) +" " + ("0" + dd.getHours()).slice(-2)+":"+("0" + dd.getMinutes()).slice(-2)+":"+("0" + dd.getSeconds()).slice(-2));                    

                                api.postData("/signre", {
                                    name:auth?.RSLT_NAME,
                                    gender:auth?.RSLT_SEX_CD,
                                    account:localStorage.getItem("remail"),
                                    email:localStorage.getItem("remail"),
                                    phone:auth?.TEL_NO,
                                    pw:password,
                                    pw2:passwordCheck,
                                    auths:auth ? JSON.stringify(auth) : '',
                                    ci:auth.CI,
                                    di:auth.DI
                                }).then(res=>{
                                    if(res?.data?.status === "SUCCESS"){

                                        if(res.data.data.id) {
                                            RegisterPopup('ok')
                                        }else{
                                            RegisterPopup('logincheck')
                                        }
                                    } else {
                                        dispatch(
                                            open({
                                                component: <PopupRegister
                                                    title=""
                                                    message={"본인인증에 실패했습니다.관리자에게 문의하세요."}
                                                    onPress={() => {
                                                        navigate(routes.login);
                                                        dispatch(close());
                                                    }}
                                                    onPressText="로그인"
                                                    />
                                            })
                                        )
                                    }
                                });
                            }
                        }}
                    >
                        비밀번호 재설정
                    </button>

                    {/*
                    <div className="pop_test_box">
                        <p onClick={() => { RegisterPopup('logincheck') }} className="pop_test_btn">1</p>
                        <p onClick={() => { RegisterPopup('confirm') }} className="pop_test_btn">2</p>
                    </div>
                    */}
                </div>
                
            </div>
        </div>
    );
}