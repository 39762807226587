import React, { useEffect, useState, useRef, forwardRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import images from "../../libs/images";

import routes from "../../libs/routes";

import { logIn, logOut } from '../../redux/usersSlice';
import { open, close } from '../../redux/popupSlice';
import { loadingStart, loadingEnd } from '../../redux/loadingSlice';
import Pagination from 'react-js-pagination';
import * as api from '../../libs/api';

export default function PopupSchool(props) {
    const { mbData, accessToken, autoLogin } = useSelector(s => s.usersReducer);


    const { seq } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(1);
    const [limit, setLimit] = useState(5);
    let [search, setSearch] = useState("");

    const onSchoolSelect = (areaName,schoolName) => {
//        props.setInfo({...props.info, area:areaName});
        props.setInfo({...props.info, school:schoolName, area:areaName});
        dispatch(close());
    }
    const onSearch = () => {
        onList(1,search);
    }
    const onList = (page,ss) => {
        if(ss){
            search = ss;
        }
        api.getDataRefresh("/school/api", {
            page:page,
            school:search
        },mbData).then(res=>{
            if(res && res?.data?.status=="SUCCESS"){
                
                const list = JSON.parse(res.data.data);
                console.log(list)
                if( list?.RESULT?.CODE === "INFO-200" ){
                    setList([]);
                    setPage(1);
                    setTotal(0);                    
                }else{
                    setList(list.schoolInfo[1].row);
                    setPage(page);
                    setTotal(list.schoolInfo[0].head[0].list_total_count);
                }
            }
        });
    }
    useEffect(() => {
        onList(1);
    },[]);
    
  const activeEnter = (e) => {
    if(e.key === "Enter") {
        onList(1,search);
        //onSchoolSelect(e.target.value);
    }
  }
  const onDirect = () => {
    onSchoolSelect("",search);
  }
    return (
        <>
            <div className="changbi pop school">
                <h1>
                    학교 및 기관 찾기
                    <button type="button" onClick={() => dispatch(close())}><img src='/images/exit-icon.svg' /></button>
                </h1>

                <div className="school-box">
                    <div>
                        <input type="text" onChange={(e)=>setSearch(e.target.value)} onKeyDown={(e) => activeEnter(e)}/>
                        <button type="button" onClick={(e)=>onSearch()}><img src='/images/icon_search.svg' /></button>
                        <small>예)안양초등학교 - “안양, “안양초”, “안양초등학교”</small>
                    </div>
                    <table>
                        <colgroup>
                            <col width="140" />
                            <col width="*" />
                            <col width="65" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>교육 지원청</th>
                                <th>학교명</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.map(val=>(
                            <tr>
                                <td>{val.JU_ORG_NM}</td>
                                <td>{val.LCTN_SC_NM} {val.SCHUL_NM}</td>
                                <td><button type="button" onClick={()=>onSchoolSelect(val.LCTN_SC_NM, val.SCHUL_NM)}>선택</button></td>
                            </tr>
                            ))}

                        </tbody>
                    </table>
                    <div className="paging">
                        <Pagination
                            activePage={page}
                            itemsCountPerPage={limit}
                            totalItemsCount={total}
                            pageRangeDisplayed={10}
                            itemClassFirst="page_first"
                            itemClassPrev="page_prev"
                            itemClassNext="page_next"
                            itemClassLast="page_last"
                            onChange={onList}
                        />
                    </div>
                    
                    <p className="ex">찾으시는 학교가 없을 경우 <strong onClick={()=>onDirect()}>직접 입력</strong>하여 주세요.</p>
                </div>                
            </div>
        </>
    );
}
