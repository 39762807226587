import React, { useEffect, useState, useRef } from 'react';
import { Routes, useNavigate, useLocation, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

export default function NaverLogin(props) {

    const { naver } = window;
    const NAVER_CLIENT_ID = "i1P2ip0WNNvI44SaEY5p";
  

    // 네이버 로그인 기능 및 버튼 구현
    const naverLogin1 = new naver.LoginWithNaverId({
        clientId: NAVER_CLIENT_ID,  // Naver Developer 에 있는 Client ID
        callbackUrl: process.env.REACT_APP_URL+"/sns/naver/1",  // 요청 보냈을때 네이버에서 응답해 줄 주소
        isPopup: true,  // 네이버 로그인 확인 창을 팝업으로 띄울지 여부
        loginButton: {
          color: "green", // green, white
          type: 2, // 1: 작은버튼, 2: 중간버튼, 3: 큰 버튼
          height: 32, // 크기는 높이로 결정한다.
        },
      });
      
    const naverLogin = new naver.LoginWithNaverId({
        clientId: NAVER_CLIENT_ID,  // Naver Developer 에 있는 Client ID
        callbackUrl: process.env.REACT_APP_URL+"/sns/naver",  // 요청 보냈을때 네이버에서 응답해 줄 주소
        isPopup: true,  // 네이버 로그인 확인 창을 팝업으로 띄울지 여부
        loginButton: {
          color: "green", // green, white
          type: 1, // 1: 작은버튼, 2: 중간버튼, 3: 큰 버튼
          height: 32, // 크기는 높이로 결정한다.
        },
      });
 
    useEffect(() => {
      if(props.type == 3 ){

      }else{
        if(props.type == 1){
            naverLogin1.init();        
        }else{
            naverLogin.init();        
        }
      }
    }, []);
  
    return (
      <>
       {props.type == 3 ?
        <p className="naver"
          onClick={()=>{
          const NAVER_CLIENT_ID = "i1P2ip0WNNvI44SaEY5p"
          const REDIRECT_URI = process.env.REACT_APP_URL+"/sns/naver"
          const STATE = "flase";
          const NAVER_AUTH_URL = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${NAVER_CLIENT_ID}&state=${STATE}&redirect_uri=${REDIRECT_URI}`;

          window.open( NAVER_AUTH_URL,"naver","width=500,height=500");
          }}
        ></p>
        :
        <div id="naverIdLogin" />
        }
      </>
    );
}
