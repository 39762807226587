
import React, { useEffect, useState, useRef } from 'react';

import { Routes, useNavigate, useLocation, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { open, close } from '../../redux/popupSlice';
import { logIn, logOut  } from '../../redux/usersSlice';
import images from "../../libs/images";
import PopupMain from '../popup/PopupMain';
import * as api from '../../libs/api';
import BtnFileDownloadAll from "../../components/file_download_all";

export default function Scrap() {
    let [tab, setTab] = useState(0);
    let [cate, setCate] = useState("전체");
    const [list, setList] = useState([]);
    const [all_checked, setAllChecked] = useState(false);
    const category = [
        ["전체","국어","사회","미술"],
        ["전체","이달의 수업","수업고민사전","창의적 체험 활동","수업 연계 도서","선생님 맞춤 자료"]
    ]
    const location = useLocation(); 
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { mbData, accessToken, autoLogin } = useSelector(s => s.usersReducer);

    useEffect(() => {
        if(!mbData.email){

            localStorage.removeItem("access");
            localStorage.removeItem("remail");
            localStorage.removeItem("page");
            localStorage.removeItem("sendData");
            dispatch(
                logOut()
            );
            dispatch(
                open({
                    component: <PopupMain
                        width={400}
                        text="로그인이 필요한 서비스입니다."
                        button="로그인"
                        buttonCancel="닫기"
                        func="login"
                        url={location.pathname}
                    />,

                })
            );   
            navigate("/login");
        } else {
            if(location?.state){
                setTab(1);
                onList(1,"");
            }else{
                onList(0,"");
            }
        }
    },[]);

    const onList = (tt,cc) => {
        if(tt >= 0){
            tab = tt;
        }
        if(cc != ''){
            cate = cc;
        }
        api.getDataRefresh("/scrap", {
            memberSeq:mbData.userSeq,
            type:tab==0 ? 2 : 0,
            category:(cate == "전체" ? "" : cate)
        },mbData).then(res=>{

            if(res && res?.data?.status=="SUCCESS"){
                setList(res.data.data);               
            } else {
                localStorage.removeItem("access");
                localStorage.removeItem("remail");
                localStorage.removeItem("page");
                localStorage.removeItem("sendData");
                dispatch(
                    logOut()
                );
                dispatch(
                    open({
                        component: <PopupMain
                            width={400}
                            text="로그인이 필요한 서비스입니다."
                            button="로그인"
                            buttonCancel="닫기"
                            func="login"
                            url={location.pathname}
                        />
                    })
                );
            }
        });
    }
    const changeTab = (no) => {

        setTab(no);
        onList(no,cate);
    }
    const changeCate = (e) => {
        setCate(e);
        onList(tab,e);
    }
    const onAllChecked = (e) => {
        setAllChecked(e.target.checked);
        setList(
            list.map(par=>
               par.seq > 0 ? { ...par, checked: e.target.checked } : par
            )
        )
    }
    const onChecked = (val) => {
        setList(
            list.map(par=>
                par.seq === val.seq ? { ...par, checked: !val.checked } : par
            )
        )
    }
    const onScrapRemove = () => {
        const formData = new FormData();
        let add="";
        list.map(function(val){
            if(val.checked){
                add += "," + val.seq;
                formData.append("seqs[]",val.seq);
            }
        });

        formData.append("memberSeq",mbData.userSeq);
        //formData.append("seqs[]",add.substring(1));

        api.postDataRefresh("/scrap/remove",formData,mbData).then(res=>{
            if(res && res?.data?.status=="SUCCESS"){
                setList(
                    list.filter(val=>!val.checked)
                );
                setAllChecked(false);
            }
        });
    }
    const onScrapDownload = () => {
        list.map(val=>{
            val.checked && (
                api.blobData("/download" ,{
                    filename:encodeURIComponent(val.file),
                    folder:"data"
                },mbData).then(res=>{
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download",`${val.oriFile}`);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                })
            )
        })
    }
    
    const tailName = (filename) => {
        const tail = filename.split(".");
        return tail[tail.length-1].trim();
    }
 
    return (
        <div className="changbi">
            <div className="wrap scrap">
                <div className="board">
                    <h1 className="title">스크랩</h1>
                    <div className="category">
                        <div className="tabs01">
                            <label>{ tab ? '구분' : '구분'}</label>
                            <div>
                            <button type="button" className={tab === 0 ? 'active' : ''} onClick={(e)=>changeTab(0)}>교과서</button>                            
                            <button type="button" className={tab === 1 ? 'active' : ''} onClick={(e)=>changeTab(1)}>게시글</button>
                            </div>
                            
                        </div>
                        <div className="tabs02">
                            <label className={`stab`+tab}>{ tab ? '분류' : '교과'}</label>                           
                            <div className={`stab`+tab}>
                            {category[tab].map((val,idx) => (
                                <button type="button" className={cate === val ? 'active' : ''} onClick={(e)=>changeCate(val)}>{val}</button>      
                            ))}
                            </div>

                        </div>                        
                    </div>
                    <div className="board-button">
                        <button type="button" onClick={(e)=>{onScrapRemove()}}><img src='/images/icon_trash.svg' />선택삭제</button>

                        <BtnFileDownloadAll folder="data" list={list} name="file" ori="fileOri" type="2"/>
                    </div>
                    <div className="board">
                        <div className="yscroll">
                        <table className="type02">
                            <colgroup>
                                <col width="80"/>
                                <col width="140"/>
                                <col width="*"/>
                            </colgroup>
                            <thead>
                                <tr>
                                    <th className="text-left"><label className="check"><input type="checkbox" checked={all_checked} onClick={(e)=>onAllChecked(e)}/></label></th>
                                    <th>{tab ? '콘텐츠명' : '과목명'}</th>
                                    <th>{tab ? '제목' : '자료명'}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {list.map(val=>(
                                <tr>
                                    <td><label className="check"><input type="checkbox" checked={val.checked} onClick={(e)=>onChecked(val)} /></label></td>
                                    <td className="text-center">{val.category}</td>
                                    <td onClick={(e)=>navigate(val.link, {state:{page:1,link:location.pathname}})} >
                                        {val.category === '이달의 수업' ? 
                                        <span>{val.title} {val?.fileOri ? <><img src='/images/hwp.svg' /> <img src='/images/pdf.svg' /> <img src='/images/zip.svg' /></> : <></> }</span>
                                        :
                                        <span>{val.title} {val?.fileOri ? <img src={'/images/'+tailName(val?.fileOri)+'.svg'} /> : <></> }</span>
                                        }
                                        </td>
                                </tr>
                                ))}
                                
                            </tbody>
                        </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
