import React, { useEffect, useState, useRef } from 'react';
import { Routes, useNavigate, useLocation, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
export default function SnsKakao() {

    const params = useParams();
    const navigate = useNavigate();
    useEffect(() => {
      
      const code = new URL(document.location.toString()).searchParams.get('code'); 
      getToken(code);
    }, []);

    const getToken = async code => {
        const grant_type = 'authorization_code';
        const client_id = '1b608287b31e72bbb1ecd455087995d5';
        let REDIRECT_URI = process.env.REACT_APP_URL+'/sns/kakao';
        if(params.id == "1"){
          REDIRECT_URI = process.env.REACT_APP_URL+'/sns/kakao/1';
        }
        
    
        const res = await axios.post(
          `https://kauth.kakao.com/oauth/token?grant_type=${grant_type}&client_id=${client_id}&redirect_uri=${REDIRECT_URI}&code=${code}`,
          {
            headers: {
              'Content-type': 'application/x-www-form-urlencoded;charset=utf-8',
            },
          },
        )
        const token = res.data.access_token;
        getKaKaoUserData(token);
    }

    const getKaKaoUserData = async token => {
        const kakaoUser = await axios.get(`https://kapi.kakao.com/v2/user/me`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
        })

        if(params.id == "1"){
          window.opener.postMessage({ type: 'kakao_up', payload: kakaoUser.data }, '*')
        }else{
          window.opener.postMessage({ type: 'kakao', payload: kakaoUser.data }, '*')
        }
        window.close();

        
        return await kakaoUser.data
    }
        
    return <div className="sns_box"><br/><br/><br/><br/><br/>카카오 로그인</div>;
}
