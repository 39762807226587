export const regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/; // 휴대폰번호
export const autoHypenPhone = (txt) => {
  return txt?.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
}
export const regTel = /^\d{2,3}-?\d{3,4}-?\d{4}$/; // 일반전화번호
export const regEmail = /^[^\sㄱ-ㅎㅏ-ㅣ가-힣]*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/; // 이메일
export const regPassword = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,15}$/; //8~15자리 영문,숫자,특수문자 홉합 사용

export const imageUrl = process.env.REACT_APP_SERVER_URL+"/image/";

export const today = () => {
  const dd = new Date();
  return (dd.getFullYear()+"-"+("0" + (dd.getMonth() + 1)).slice(-2)+"-"+("0" + dd.getDate()).slice(-2) +" " + ("0" + dd.getHours()).slice(-2)+":"+("0" + dd.getMinutes()).slice(-2)+":"+("0" + dd.getSeconds()).slice(-2));
}
export const timeForToday = (value) => {
  const today = new Date();
  const timeValue = new Date(value);

  const betweenTime = Math.floor(
    (today.getTime() - timeValue.getTime()) / 1000 / 60
  );
  if (betweenTime < 1) return "방금전";
  if (betweenTime < 60) {
    return `${betweenTime}분전`;
  }

  const betweenTimeHour = Math.floor(betweenTime / 60);
  if (betweenTimeHour < 24) {
    return `${betweenTimeHour}시간전`;
  }

  const betweenTimeDay = Math.floor(betweenTime / 60 / 24);
  if (betweenTimeDay < 7) {
    return `${betweenTimeDay}일전`;
  }

  return value.replaceAll("-",".");
};

export const b64DecodeUnicode = (str)  => {
  if(str){
    const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
    if(base64regex.test(str)){
      return decodeURIComponent(window.atob(str).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
    }else{
      return str;
    }
  }else{
    return str;
  }
}

export const chunkFunc = (data = [], size = 1) => {
    const arr = [];
      
    for (let i = 0; i < data.length; i += size) {
      arr.push(data.slice(i, i + size));
    }
  
    return arr;
}
