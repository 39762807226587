import React, { useEffect, useState, useRef } from 'react';
import { Routes, useNavigate, useLocation, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { open, close } from '../../redux/popupSlice';
import { logIn, logOut  } from '../../redux/usersSlice';
import InnerMenu from "../../components/innovation_menu";
import * as api from '../../libs/api';
import { imageUrl } from "../../libs/utils";
import BtnScrap from '../../components/btn_scrap';
import BtnFileDownload from '../../components/file_download';
import PopupMain from '../../pages/popup/PopupMain';

export default function MonthInfo(props) {

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { type, id} = useParams();
    const [info, setInfo] = useState({});
   

    useEffect(() => {
        let link = "/calendar/info/"+id;
        if(props?.name === "preview"){
            link = "/preview/calendar/info/"+id;
        }
        api.getData(link ,{
        }).then(res=>{

            if(res && res?.data?.status=="SUCCESS"){
                setInfo(res.data.data);
            }
        });


        //setInfo(location?.state);
    },[type, id])
    const goUrl = () => {
        if(location?.state?.link){
            if(location?.state?.school){
                navigate(location?.state?.link,{state:{month:location?.state?.month,school:location?.state?.school}})
            }else{
                navigate(location?.state?.link,{state:{month:location?.state?.month}})
            }
        }else{
            navigate(-1);
        }
        
    }
    return (
        <div className="changbi">
            <div className="wrap">
                <InnerMenu m_title="수업 혁신"/>
                <div className="box">
                    <h1 className="title">이달의 수업</h1>
                    <img src={imageUrl+"dictionary?file="+info?.fileImage} alt="상세 대표 이미지" className="info-image" width="100%"/>
                    {/*<img src='/images/ex_img7} alt="상세 대표 이미지" className="m_image info-image"/>*/}
                    <h2 className="info-title">
                        <small>{info?.school}</small><small>{info?.subject}</small>
                        {/*<span>{info?.title}</span>*/}
                        <BtnScrap info={info} />
                    </h2>
                    <div className="info-editor"  dangerouslySetInnerHTML={{__html: info?.contents}} ></div>
                    <dl className="offer-data">
                        <dt>수업 제안 자료</dt>
                        {info?.fileZip3!="" && (
                        <dd>
                            한 번에 받기
                            {info?.fileZip3 && <BtnFileDownload folder="dictionary" type="3" text="ZIP" filename={info?.fileZip3} filename_original={info?.fileZipOri3} />}
                        </dd>
                        )}
                        {info?.filePdf1!="" && info?.fileHwp1!="" && (
                        <dd>
                            수업지도안
                            {info?.filePdf1 && <BtnFileDownload folder="dictionary" type="3" text="PDF" filename={info?.filePdf1} filename_original={info?.filePdfOri1} />}
                            {info?.fileHwp1 && <BtnFileDownload folder="dictionary" type="3" text="HWP" filename={info?.fileHwp1} filename_original={info?.fileHwpOri1} />}
                        </dd>
                        )}
                        {info?.filePdf2!="" && info?.fileHwp2!="" && (
                        <dd>
                            활동지 / 평가지
                            {info?.filePdf2 && <BtnFileDownload folder="dictionary" type="3" text="PDF" filename={info?.filePdf2} filename_original={info?.filePdfOri2} />}
                            {info?.fileHwp2 && <BtnFileDownload folder="dictionary" type="3" text="HWP" filename={info?.fileHwp2} filename_original={info?.fileHwpOri2} />}
                        </dd>
                        )}
                    </dl>
                    <div className="info-button">
                        <button type="button" onClick={()=>goUrl()}>목록</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
