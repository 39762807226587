/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import routes from '../libs/routes';
import images from "../libs/images";
import Popup from "./popup/Popup";
import { chunkFunc,imageUrl } from "../libs/utils";

import { useDispatch, useSelector, batch  } from 'react-redux';
import { open, close } from '../redux/popupSlice';
import { getData as getEtcData } from '../redux/etcSlice';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, FreeMode } from 'swiper/modules';

import BtnFileDownload from '../components/file_download';


import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/free-mode';
import * as api from '../libs/api';


export default function Main() {
    const [school, setSchool] = useState(['국어','사회','미술']);    
    const today = new Date();
    const [main, setMain] = useState();
    const [month, setMonth] = useState(today.getMonth()+1);
    const [width, setWidth] = useState(window.innerWidth <= 600);

    const mainImage = images.banner_main;
    const subImage = images.banner_main_mobile;
    
    const dispatch = useDispatch();
    const handleResize = () => {
        setWidth(window.innerWidth <= 600);
    };


    
    useEffect(() => {       
        window.addEventListener('resize', handleResize);
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    })

    const [current, setCurrent] = useState(1);
    const [total, setTotal] = useState(1); // 초기 total 값
    const [isAutoplayEnabled, setIsAutoplayEnabled] = useState(true);
    const [swiperInstance, setSwiperInstance] = useState(null);
    



    const toggleAutoplay = () => {
        if (isAutoplayEnabled) {
            swiperInstance.autoplay.stop();
        } else {
            swiperInstance.autoplay.start();
        }
        setIsAutoplayEnabled(!isAutoplayEnabled);
    };

    const pauseImage = isAutoplayEnabled ? images.pause : images.play;

    const fillPer = (current / total) * 100;
    const fillWidth = fillPer + '%';


    const [updateFileList, setUpdateFileList] = useState([]);

    

    useEffect(() => {
  
        api.getData("/main", {
        }).then(res=>{
            if(res?.headers?.get("New-Access-Token")){
                localStorage.setItem("access", res.headers.get("New-Access-Token"));
            }
            if(res && res?.data?.status=="SUCCESS"){
                const ret = res.data.data;
                
                setTotal(ret?.topBanner.filter(val=>val.type==1).length);
               

                setMain(ret);
                setUpdateFileList(chunkFunc(ret.update, 3));
                
                if(ret?.innovation.length > 0){
                    setMonth(ret?.innovation[0].month);
                } else {
                    setMonth(today.getMonth()+1);
                }

            }
        });
    }, []);
    
    const navigate = useNavigate();
    function goUrl(url) {
        navigate(url);
    }
    const onTagLink = (type,text) => {
        //navigate("/board/"+type+"?text="+text)
    }
    const onSwiperLink = (url) => {
        if(url.indexOf("http")>=0){
            window.open(url);
        }else{
            navigate(url);
        }
    }

    return (
            <div className="main_box">
                {main?.popup?.map(val=>
                    !localStorage.getItem("homeVisited"+val.seq) && <Popup link={val.url} image={imageUrl+"banner?file="+val.image} seq={val.seq}  />
                )}

                <div className="main_part banner">
                    <div className="main_box_sub">
                        <div className="main_swiper_box">
                            {total?.length > 1 && <>
                            <div className="main_swiper1_next"><img src='/images/update-right.svg' /></div>
                            <div className="main_swiper1_prev"><img src='/images/update-left.svg' /></div>
                            </>
                            }
                            {/* <div className="pagination_container">
                                <span className="current">01</span>
                                <div className="swiper-pagination"></div>
                                <img src='/images/pause} />
                                <span className="total">05</span>
                            </div> */}
                            
                            <Swiper className="main_swiper1"  style={{ width: '100%', height: '320px', borderRadius: '12px'}}
                                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                                spaceBetween={0}
                                slidesPerView={1}
                                loop={true}
                                onSlideChange={(swiper) => {
                                        if( swiper?.realIndex >= 0 ){
                                            setCurrent(swiper.realIndex + 1)
                                        }
                                    }
                                }
                                onInit={(swiper) => {
                                    setSwiperInstance(swiper);
                                }}
                                autoplay={isAutoplayEnabled ? { delay: 3000 } : false}
                                /* pagination={{
                                    el: ".swiper-pagination",
                                    type: "progressbar",
                                }} */
                                pagination= {{
                                    el: ".custom-pagination",
                                    type: "custom",
                                }}
                                navigation={{
                                    nextEl: '.main_swiper1_next',
                                    prevEl: '.main_swiper1_prev',
                                }}
                                >
                                {main?.topBanner.map((val)=> 
                                    val.type==1 && <SwiperSlide onClick={(e)=>onSwiperLink(val.url)}><img src={width ? imageUrl+"banner?file="+val.mobile : imageUrl+"banner?file="+val.pc}/></SwiperSlide>
                                )}
                            </Swiper>
                            
                            {total && total > 1 &&
                            <div className="custom-pagination">
                                <strong>0<span className="current">{current}</span></strong>
                                <div className="progress__bar">
                                    <span className="progress__fill" style={{ width: fillWidth }}></span>
                                </div>
                                <span className="total">{total && total?.length == 1 ? '0'+total : total}</span>
                                <img src={pauseImage} alt="Play/Pause" onClick={toggleAutoplay} />
                            </div>
                            }

                            
                        </div>
                    </div>
                </div>

                <div className="main_part lesson">
                    <div className="main_box_sub">
                        <div className="main_content1">

                            <div className="main_centent1_sub1 pc">
                                <div className="title_box swiper_title">
                                    <p>{month}월 이달의 수업</p>
                                    <button type="button" onClick={()=>navigate("/calendar")}>더 보기</button>
                                </div>

                                <div className="content_box">

                                    {main?.innovation.map((val, key)=> 

                                    <div className="subject_box" onClick={()=>navigate("/month/"+(val.subject==='국어' ? 0 : (val.subject === '사회' ? 1 : 2))+"/"+val.innovationSeq, {state:val})}>
                
                                        <div className="img_box" >
                                            <img src={imageUrl+"dictionary?file="+val.fileThumbnail} width="158"/>
                                            {/*<p className="comment">{val.title}</p>*/}
                                        </div>
                                        
                                        {/*
                                        <div className="subject">
                                            <p className={"name1" + " color"+key}>{val.subject}</p>
                                            <p className="name2">{val.school}</p>
                                        </div>
                                        */}
                                    </div>
                                    )}
                                </div>

                                <Swiper className="main_swiper2 mobile" style={{display: 'none'}}
                                modules={[FreeMode]}
                                freeMode={true}
                                spaceBetween={12}
                                slidesPerView={2.2}
                                breakpoints={{
                                    690: {
                                        slidesPerView: 4,
                                        spaceBetween: 12
                                    },
                                    600: {
                                        slidesPerView: 3.5,
                                        spaceBetween: 12
                                    },
                                    490: {
                                        slidesPerView: 2.8,
                                        spaceBetween: 12
                                    }
                                }}
                                >
                                    {main?.innovation.map((val, key)=> 
                                    <SwiperSlide>
                                        <div className="subject_box" onClick={()=>navigate("/month/"+(val.subject==='국어' ? 0 : (val.subject === '사회' ? 1 : 2))+"/"+val.innovationSeq)}>
                                            <div className="img_box" >
                                                <img src={imageUrl+"dictionary?file="+val.fileThumbnail} width="158"/>
                                            </div>
                                            
                                            <div className="subject">
                                                <p className={"name1" + " color"+key}>{val.subject}</p>
                                                <p className="name2">{val.school}</p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    )}
                                
                                </Swiper>
                            </div>

                            

                            <div className="line"></div>

                            <div className="main_centent1_sub1 _2 ">
                                <div className="title_box">
                                    <p>수업 고민 사전</p>
                                    <button type="button" onClick={(e)=>navigate("/dictionary")}>더 보기</button>
                                </div>

                                <div className="comment">수업 고민 사전은 과목별 핵심 학습 표제어의 개념은 물론 현장 선생님들의 생생한 수업 노하우를 함께 제시한, 세상 어디에서도 볼 수 없었던 수업 안내 사전입니다.</div>

                                <div className="shortcut_box">
                                    <div className="shortcut" style={{background: '#FFE8F0'}} onClick={(e)=>navigate("/dictionary/0/0")}>
                                        <img src='/images/short1.svg' />
                                        <p className="text1">국어 수업<br />고민 사전</p>
                                        <p className="text2">바로 가기</p>
                                    </div>

                                    <div className="shortcut" style={{background: '#DCF4FF'}} onClick={(e)=>navigate("/dictionary/1/12")}>
                                        <img src='/images/short2.svg' />
                                        <p className="text1">사회 수업<br />고민 사전</p>
                                        <p className="text2">바로 가기</p>
                                    </div>

                                    <div className="shortcut" style={{background: '#FFF5DB'}} onClick={(e)=>navigate("/dictionary/2/13")}>
                                        <img src='/images/short3.svg' />
                                        <p className="text1">미술 수업<br />고민 사전</p>
                                        <p className="text2">바로 가기</p>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div className="main_content2">

                    <div className="learning_data_box">
                        <div className="learning_data">
                            <div className="title_box swiper_title">
                                <p>
                                    <img src='/images/light.svg'/>
                                    창의적 체험 활동
                                </p>
                                <button type="button" className="margin_btn" onClick={(e)=>navigate("/creative")}>더 보기</button>
                            </div>
                            <Swiper className="main_swiper3_mobile"
                                modules={[FreeMode]}
                                freeMode={true}
                                spaceBetween={12}
                                slidesPerView={2.2}
                                breakpoints={{
                                    1000: {
                                        slidesPerView: 5,
                                        spaceBetween: 12,
                                    },
                                    850: {
                                        slidesPerView: 4.5,
                                        spaceBetween: 12,
                                    },
                                    750: {
                                        slidesPerView: 4,
                                        spaceBetween: 12,
                                    },
                                    650: {
                                        slidesPerView: 3.5,
                                        spaceBetween: 12,
                                    },
                                    550: {
                                        slidesPerView: 3,
                                        spaceBetween: 12,
                                    },
                                    450: {
                                        slidesPerView: 2.5,
                                        spaceBetween: 12,
                                    }
                                }}
                                >
                                {main?.creative?.map((val, key)=> 
                                <SwiperSlide>
                                    <div className="post_box swiper" onClick={()=>navigate("/creative/view/"+val.categorySeq+"/"+val.boardSeq, {state:val})}>
                             
                                        <div className="post_back" style={{background: 'url('+imageUrl+"board?file="+val.thumbnail+') no-repeat center center', backgroundSize: 'cover'}}>

                                        </div>
                                        <p className="post_title">{val.title}</p>
                                        <div className="post_tag" >
                                            {val.tag && 
                                                val.tag.split(",").map(x=>
                                                    (<p onClick={(e)=>onTagLink(0,x)}>#{x.trim()}</p>)
                                            )}
                                        </div>
                                    </div>
                                </SwiperSlide>
                                )}

                                
                            </Swiper>
                        </div>

                        <div className="line"></div>

                        <div className="learning_data">
                            <div className="title_box swiper_title">
                                <p>
                                    <img src='/images/learning.svg'/>
                                    선생님 맞춤 자료
                                </p>
                                <button type="button" className="margin_btn" onClick={(e)=>navigate("/teacher")}>더 보기</button>
                            </div>


                            <Swiper className="main_swiper3_mobile"
                                modules={[FreeMode]}
                                freeMode={true}
                                spaceBetween={12}
                                slidesPerView={2.2}
                                breakpoints={{
                                    1000: {
                                        slidesPerView: 5,
                                        spaceBetween: 12,
                                    },
                                    850: {
                                        slidesPerView: 4.5,
                                        spaceBetween: 12,
                                    },
                                    750: {
                                        slidesPerView: 4,
                                        spaceBetween: 12,
                                    },
                                    650: {
                                        slidesPerView: 3.5,
                                        spaceBetween: 12,
                                    },
                                    550: {
                                        slidesPerView: 3,
                                        spaceBetween: 12,
                                    },
                                    450: {
                                        slidesPerView: 2.5,
                                        spaceBetween: 12,
                                    }
                                }}
                                >
                                
                                {main?.teacher?.map((val, key)=> 
                                <SwiperSlide>
                                    <div className="post_box swiper" onClick={()=>navigate("/teacher/view/"+val.categorySeq+"/"+val.boardSeq, {state:val})}>
                                        <div className="post_back" style={{background: 'url('+imageUrl+"board?file="+val.thumbnail+') no-repeat center center', backgroundSize: 'cover'}}>

                                        </div>
                                        <p className="post_title">{val.title}</p>
                                        <div className="post_tag" >
                                            {val.tag && 
                                                val.tag.split(",").map(x=>
                                                    (<p onClick={(e)=>onTagLink(2,x)}>#{x.trim()}</p>)
                                            )}
                                        </div>
                                    </div>
                                </SwiperSlide>
                                )}  
                                
                            </Swiper>
                        </div>

                        <div className="line"></div>

                        <div className="learning_data book">
                            <div className="title_box swiper_title">
                                <p>
                                    <img src='/images/book.svg'/>
                                    수업연계도서
                                </p>
                                <button type="button" className="margin_btn" onClick={(e)=>navigate("/book")}>더 보기</button>
                            </div>

                            <Swiper className="main_swiper3_mobile"
                                modules={[FreeMode]}
                                freeMode={true}
                                spaceBetween={12}
                                slidesPerView={2.2}
                                breakpoints={{
                                    1000: {
                                        slidesPerView: 5,
                                        spaceBetween: 12,
                                    },
                                    850: {
                                        slidesPerView: 4.5,
                                        spaceBetween: 12,
                                    },
                                    750: {
                                        slidesPerView: 4,
                                        spaceBetween: 12,
                                    },
                                    650: {
                                        slidesPerView: 3.5,
                                        spaceBetween: 12,
                                    },
                                    550: {
                                        slidesPerView: 3,
                                        spaceBetween: 12,
                                    },
                                    450: {
                                        slidesPerView: 2.5,
                                        spaceBetween: 12,
                                    }
                                }}
                                >
                                {main?.book?.map((val, key)=> 
                                <SwiperSlide>
                                    <div className="post_box swiper"onClick={()=>navigate("/book/view/"+val.categorySeq+"/"+val.boardSeq, {state:val})}>
                                        <div className="post_back book" style={{background: 'url('+imageUrl+"board?file="+val.thumbnail+') no-repeat center center', backgroundSize: 'cover'}}>

                                        </div>
                                        <p className="post_title">{val.title}</p>
                                        <div className="post_tag" >
                                            {val.tag && 
                                                val.tag.split(",").map(x=>
                                                    (<p onClick={(e)=>onTagLink(1,x)}>#{x.trim()}</p>)
                                            )}
                                        </div>
                                    </div>
                                </SwiperSlide>
                                )}
                            </Swiper>
                        </div>
                    </div>

                </div>

                <div className="main_content3">
                    
                    <div className="bottom_other_box">
                        {main?.topBanner.map((val)=> 
                                val.type==2 && <div className="event_box" onClick={(e)=>onSwiperLink(val.url)} ><img src={width ? imageUrl+"banner?file="+val.mobile : imageUrl+"banner?file="+val.pc} /></div>
                        )}



                        <div className="update_box">
                            <div className="title_box">
                                <p className="title" >업데이트 자료 </p>
                                <button type="button" onClick={()=>navigate("/update/0")}>더 보기</button>
                                {/*
                                <div className="navi_btn_box">
                                    <div className="navi_btn update_prev"></div>
                                    <div className="navi_btn update_next"></div>
                                </div>
                                */}
                            </div>
                            <div className="update_con">
                                <p className="slide_post" onClick={()=>navigate("/update/1")}>1. 중학교 자료실 바로가기</p>
                                <p className="slide_post" onClick={()=>navigate("/update/2")}>2. 고등학교 자료실 바로가기</p>
                            </div>

                            {/*
                            <Swiper className="update_box_swiper" style={{maxWidth: '398px', width: '100%'}}
                                modules={[Navigation, Scrollbar, A11y]}
                                spaceBetween={0}
                                slidesPerView={1}
                              
                                navigation={{
                                    nextEl: '.update_next',
                                    prevEl: '.update_prev',
                                }}
                                >
                                    
                                    {updateFileList?.map((x, i) => {
                                        return (
                                            <SwiperSlide key={i}>
                                                {x?.map((xx, ii) => {
                                                    return (
                                                        <p key={i+""+ii} className="slide_post" >{xx?.title}
                                                            {xx?.file && (<BtnFileDownload  folder="board" filename={xx?.file} filename_original={xx?.fileOri}/>)}
                                                        </p>
                                                                                                            )
                                                })}
                                            </SwiperSlide>
                                        )
                                    })}
                            </Swiper>
                            */}
                            
                        </div>
                    </div>

                </div>

                <div className="main_content4">

                    <div className="service_box">
                                    
                            <div className="service_tap" style={{borderRight: '1px solid #ddd'}}>
                                <a href={main?.agree?.mainWriter} target="_blank">작가 강연 신청</a>
                                <a href={main?.agree?.mainBuilding} target="_blank">창비서교빌딩 대관</a>
                                <div className="service_call">
                                    <img src='/images/service-call.svg'/>
                                    <div>
                                        <p className="title">전화 문의</p>
                                        <p className="call_num">1833-7247</p>
                                    </div>
                                </div>
                            </div>

                            <div className="service_tap notice" style={{borderRight: '1px solid #ddd'}}>
                                <p className="title" style={{marginBottom: '20px'}} onClick={()=>navigate("/notice")}>공지사항</p>
                                <div className="notice_post_list">

                                    {main?.notice.map((val)=>                                         
                                    <div className="notice_post" onClick={()=>navigate("/notice/"+val.noticeSeq)}>
                                        <p className="title">{val.title}</p>
                                        <p className="date">{val.registDate.substr(0,10)}</p>
                                    </div>
                                    )}

                                </div>
                            </div>

                            <div className="service_tap client" style={{borderRight: '1px solid #ddd'}}>
                                <p className="title" style={{marginBottom: '20px'}}>고객센터</p>

                                <p className="link" onClick={()=>navigate("/notice")}>공지사항</p>
                                <p className="link" onClick={()=>navigate("/my/qna")} style={{margin: '12px 0'}}>문의하기</p>
                                <p className="link" onClick={()=>navigate("/faq")}>자주하는 질문</p>
                            </div>

                            <div className="service_tap client">
                                <p className="title" style={{marginBottom: '20px'}}>더보기</p>

                                <p className="link" onClick={()=>navigate("/event")}>이벤트</p>
                                <p className="link" onClick={()=>navigate("/update/0")} style={{marginTop: '12px'}} >업데이트 자료실</p>
                            </div>

                    </div>                    
                    <div className="service_box mobile one" style={{display: 'none'}}>

                        <div className="service_box_group">            
                            <div className="service_tap" style={{borderRight: '1px solid #ddd', borderBottom: '1px solid #ddd'}}>
                                <a href="https://docs.google.com/forms/d/e/1FAIpQLScCt7u3Msm3ga3eFiVV_ytM_iA6Yrs_bAqmMgW3XGD0z-qjmw/viewform?pli=1" target="_blank">작가 강연 신청</a>
                                <a href="http://changbi.net/" target="_blank">창비서교빌딩 대관</a>
                                <div className="service_call">
                                    <img src='/images/service-call.svg'/>
                                    <div>
                                        <p className="title">전화 문의</p>
                                        <p className="call_num">1833-7247</p>
                                    </div>
                                </div>
                            </div>

                            <div className="service_tap notice" style={{ borderBottom: '1px solid #ddd'}}>
                                <p className="title" style={{marginBottom: '20px'}}  onClick={()=>navigate("/notice")}>공지사항</p>
                                <div className="notice_post_list">
                                    {main?.notice.map((val)=>                                         
                                    <div className="notice_post" onClick={()=>navigate("/notice/"+val.noticeSeq)} >
                                        <p className="title">{val.title}</p>
                                        <p className="date">{val.registDate.substr(0,10)}</p>
                                    </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="service_box_group">
                            <div className="service_tap client plus" style={{borderRight: '1px solid #ddd'}}>
                                <p className="title" style={{marginBottom: '20px'}}>고객센터</p>

                                <p className="link" onClick={()=>navigate("/notice")}>공지사항</p>
                                <p className="link" onClick={()=>navigate("/my/qna")} style={{margin: '12px 0'}}>문의하기</p>
                                <p className="link" onClick={()=>navigate("/faq")}>자주하는 질문</p>
                            </div>

                            <div className="service_tap client last">
                                <p className="title" style={{marginBottom: '20px'}}>더 보기</p>

                                <p className="link" onClick={()=>navigate("/event")}>이벤트</p>
                                <p className="link" onClick={()=>navigate("/update/0")} style={{marginTop: '12px'}}>업데이트 자료실</p>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
    );
}