import React, { useEffect, useState, useRef } from 'react';
import { Routes, useNavigate } from "react-router-dom";
import routes from '../libs/routes';
import images from "../libs/images";

export default function FindPage() {
  

    return (
        <div className="login_back changbi">
            <div className="login_box nopage-box">          
                <p>404 - Page not found.</p>
            </div>
        </div>
    );
}
