import { createSlice } from '@reduxjs/toolkit';

const etcSlice = createSlice({
  name: 'etc',
  initialState: {
    topBanner: 1,
    topBannerPc: "",
    topBannerMobile: "",
    topBannerUrl: "",
    envetTitle: ""
  },
  reducers: {
    setData(state, action) {
      state[action.payload.key] = action.payload.value;
    },
    getData(state, action) {
      state.topBannerPc = action.payload.pc;
      state.topBannerMobile = action.payload.mobile;
      state.topBannerUrl = action.payload.url;
      state.eventTitle = action.payload.eventTile;
    }
  },
});

export const {
  setData,
  getData
} = etcSlice.actions;

export default etcSlice.reducer;
