import React, { useEffect, useState, useRef, forwardRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import images from "../../libs/images";

import routes from "../../libs/routes";

import { logIn, logOut } from '../../redux/usersSlice';
import { open, close } from '../../redux/popupSlice';
import { loadingStart, loadingEnd } from '../../redux/loadingSlice';
import * as api from '../../libs/api';

import BtnFileDownload from "../../components/file_download";
import BtnFileDownloadAll from "../../components/file_download_all";
import BtnScrap from "../../components/btn_scrap";
import BtnScrapAll from "../../components/btn_scrap_all";

export default function PopupDictionary(props) {

    const { type,id,title } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [tab, setTab] = useState("말하기/듣기");
    const [list, setList] = useState([]);
    const [category, setCategory] = useState([]);
    const [search_text, setSearchText] = useState();
    const [unitLevelSeq, setUnitLevelSeq] = useState();

    
    const onCategoryList = (unitLevelSeq) => {
        setUnitLevelSeq(unitLevelSeq);
        api.getData("/dictionary", {
            unitSeq:id,
            unitLevelSeq:unitLevelSeq,
            text:search_text ? search_text : '',
            page:0,
            size:999
        }).then(res=>{
            if(res && res?.data?.status=="SUCCESS"){
                setList(res.data.data.content);               
            }
        });
    }
    
    const onTabList = (type) => {
        setTab(type);
        api.getData("/dictionary", {
            type:type,
            text:search_text ? search_text : '',
            page:0,
            size:999
        }).then(res=>{
            if(res && res?.data?.status=="SUCCESS"){
                setList(res.data.data.content);               
            }
        });
    }

    const onSearch = () => {
        onCategoryList(unitLevelSeq)  
    }
    const onAllChecked = (e) => {
        setList(
            list.map(par=>
               par.dictionarySeq > 0 ? { ...par, checked: e.target.checked } : par
            )
        )
    }

    const onChecked = (val) => {
        setList(
            list.map(par=>
                par.dictionarySeq === val.dictionarySeq ? { ...par, checked: !val.checked } : par
            )
        )
    }
   


    useEffect(() => {
        api.getData("/dictionary/unit/level", {
            unitSeq:id
        }).then(res=>{
            if(res && res?.data?.status=="SUCCESS"){
                setCategory(res.data.data);               
            }
        });

        if( type  === 1){ 
            onCategoryList(0);
        }else{
            onTabList(tab);
        }
    },[id])

    return (
        <>
            <div className="changbi pop">
                <h1>
                    {title ? title : '국어'}
                    <button type="button" onClick={() => dispatch(close())}><img src='/images/exit-icon.svg' /></button>
                </h1>
                <div className="form">
                    <div className="search">
                        {type === 1 ? 
                        <div className="select">
                            <label>단원</label>
                            <select onChange={(e)=>onCategoryList(e.target.value)}>
                                <option value="0">전체</option>
                                {category.map((val)=>(
                                    <option value={val.unitLevelSeq}>{val.name}</option>
                                ))}
                            </select>
                        </div>
                        :
                        <div className="tag">
                            <label>영역</label>
                            <ul>
                                <li><button type="button" className={tab==="말하기/듣기" && "active"} onClick={()=>onTabList("말하기/듣기")}>말하기/듣기</button></li>
                                <li><button type="button" className={tab==="읽기" && "active"} onClick={()=>onTabList("읽기")}>읽기</button></li>
                                <li><button type="button" className={tab==="쓰기" && "active"} onClick={()=>onTabList("쓰기")}>쓰기</button></li>
                                <li><button type="button" className={tab==="문법" && "active"} onClick={()=>onTabList("문법")}>문법</button></li>
                                <li><button type="button" className={tab==="문학" && "active"} onClick={()=>onTabList("문학")}>문학</button></li>
                                <li><button type="button" className={tab==="매체" && "active"} onClick={()=>onTabList("매체")}>매체</button></li>
                            </ul>
                        </div>
                        }
                        <div className="input">
                            <input type="text" onChange={(e)=>setSearchText(e.target.value)} value={search_text}/>
                            {search_text ? 
                            <button type="button" className="text-remove" onClick={(e)=>setSearchText("")}><img src='/images/close-circle-fill.svg' /></button>
                            :
                            <></>
                            }
                            <button type="button" onClick={(e)=>onSearch(e)}><img src='/images/icon_search.svg' /></button>
                        </div>
                    </div>
                    <div className="list">
                        <dl className="scroll">
                            <dt>
                                <label className="check"><input type="checkbox" onClick={(e)=>onAllChecked(e)}/><span>전체 선택</span></label>
                                <BtnScrapAll list={list} type="1" />
                                <BtnFileDownloadAll folder="dictionary" list={list} name="addFile" ori="addFileOri"/>
                            </dt>
                            {list.map((val) => (
                            <dd>
                                 <label className="check"><input type="checkbox" checked={val.checked} onClick={(e)=>onChecked(val)}/></label>
                                 <span>{val.name}</span>
                                 <div className="pd">
                                    <BtnScrap info={val} type="1"/>
                                    <BtnFileDownload folder="dictionary" text="" filename={val.addFile} filename_original={val.addFileOri} image='/images/button_download.svg'/>
                                 </div>
                            </dd>
                            ))}

                        </dl>
                    </div>
                    <div className="popup-btn">
                        <button type="button" onClick={() => dispatch(close())}>닫기</button>
                    </div>
                </div>
            </div>
        </>
    );
}
