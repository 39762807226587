import React, { useEffect, useState, useRef } from 'react';
import { Routes, useNavigate, useLocation, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { open, close } from '../../redux/popupSlice';
import { logIn, logOut  } from '../../redux/usersSlice';
import PopupMain from '../../pages/popup/PopupMain';

import * as api from '../../libs/api';
export default function Faq() {
    
    const [text, setText] = useState();
    const [open2, setOpen] = useState();
    const [ori, setOri] = useState();
    const [list, setList] = useState();
    const [cate_open, setCateOpen] = useState(0);
    const [cate, setCate] = useState();
    const dispatch = useDispatch();


    const location = useLocation(); 
    const navigate = useNavigate();
    
    const onCateSelect = (val) => {
        setList(ori.filter(par=>
            par.faqCategorySeq == val.faqCategorySeq
        ));

        setCateOpen(val.faqCategorySeq);
        setOpen(-1)
    }
    const onCateAll = () => {
        setList(ori);       
        setCateOpen(0);
        setOpen(-1)
    }
    
    const onOpen = (val) => {
        if(open2 === val.faqSeq){
            setOpen(-1)
        }else{
            setOpen(val.faqSeq);
            api.getData("/faq/hit",{id:val.faqSeq}).then(res=>{
                if(res && res?.data?.status=="SUCCESS"){
                    
                }
            });
        }
    }


    const onSearch = (e) => {
        e.preventDefault();
        setList(ori.filter(par=>
            (par.title.indexOf(text) >= 0 || par.contents.indexOf(text) >= 0)
        ));
    }

    useEffect(() => {
        api.getData("/faq/category" ,{}).then(res=>{
 
            if(res && res?.data?.status=="SUCCESS"){
                setCate(res.data.data);
            }
        });

        api.getData("/faq" ,{}).then(res=>{
            if(res && res?.data?.status=="SUCCESS"){
                setList(res.data.data);
                setOri(res.data.data);
            }
        });        
    },[]);
    
    return (
        <div className="changbi">
            <div className="wrap">
                <div className="board faq">
                    <h1>자주하는 질문</h1>
                    <div className="category-box">
                        <div className={"category" + (cate?.length % 2 === 1 ? ' even' : ' odd') }>
                            <button type="button" className={cate_open === 0 ? 'active': ''} onClick={(e)=>onCateAll()}>전체</button>
                            {cate && cate?.map((val)=>
                                <button type="button" className={cate_open === val.faqCategorySeq ? 'active': ''} onClick={(e)=>onCateSelect(val)}>{val.name}</button>
                            )}
                        </div>
                    </div>
                    <dl className="list-faq">
                        <dt class="dt-faq">
                            <strong>전체</strong>에 대한 질문 총 <strong>{list?.length}</strong>건
                            <form onSubmit={(e)=>onSearch(e)}>
                                <input type="text" onChange={(e)=>setText(e.target.value)} value={text} />
                                {text
                                ? <button type="button" onClick={(e)=>setText("")} className="text-remove"><img src='/images/close-circle-fill.svg' /></button>
                                : <></>
                                }
                                <button type="button" onClick={(e)=>onSearch(e)}><img src='/images/icon_search.svg' /></button>
                            </form>
                        </dt>
                        {list && list?.map((val) => (
                        
                        <dd onClick={(e)=>onOpen(val)} className={val.faqSeq===open2 ? 'dd-faq open' : 'dd-faq'}>
                            <h2 className="h2-faq">
                                <small>{cate?.find(par=>par.faqCategorySeq === val.faqCategorySeq).name}</small>
                                <span>{val.title}</span>
                            </h2>
                            <div className="div-faq">
                                <p dangerouslySetInnerHTML={{__html: val.contents}}></p>
                                <p className="p-faq">
                                원하시는 정보를 찾지 못하셨나요? 1:1 문의를 남겨주시거나<br/>
                                고객센터(1833-7247)로 문의해 주시면 친절히 안내해 드리겠습니다.
                                </p>
                                <Link to="/my/qna" className="a-faq">1:1문의</Link>
                            </div>
                        </dd>
                       
                        ))}
                    </dl>
                </div>

            </div>
        </div>
    );
}
