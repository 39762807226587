import React, { useEffect, useState, useRef } from 'react';

import { Routes, useNavigate, useLocation, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { open, close } from '../../redux/popupSlice';
import { logIn, logOut  } from '../../redux/usersSlice';
import images from "../../libs/images";
import PopupSchool from '../popup/PopupSchool';
import PopupMain from '../popup/PopupMain';
import * as api from '../../libs/api';
import { regEmail,regPassword, autoHypenPhone,today,b64DecodeUnicode } from '../../libs/utils';
import NaverLogin from '../../components/naver_login';
import KakaoLogin from '../../components/kakao_login';
export default function Info() {
    
    const memberGrade = ["일반","선생님","편집자","영업","","","","","관리자","최고관리자"];
    
    const location = useLocation(); 
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { mbData, accessToken, autoLogin } = useSelector(s => s.usersReducer);

    const [teacher_value, setTeacherValue] = useState("선생님 인증을 완료해주세요.");
    let [info, setInfo] = useState({});
    const [auth, setAuth] = useState();
    const [user, setUser] = useState(null);

    useEffect(() => {
  
        api.getDataRefresh("/member", {
            userSeq:mbData.userSeq
        },mbData).then(res=>{
  
            if(res && res?.data?.status=="SUCCESS"){
                setInfo(res.data.data);
            }else{
               
                localStorage.clear() 
                dispatch(
                    logOut()
                );
                dispatch(
                    open({
                        component: <PopupMain
                            width={400}
                            text="로그인이 필요한 서비스입니다."
                            button="로그인"
                            buttonCancel="닫기"
                            func="login"
                            url={location.pathname}
                        />,
                    })
                );  
                navigate("/login");  
            
            }
        });
    
    },[]);


    useEffect(() => {
        const childMessage = (e) => {
            

            if (e.data.type !== 'teacher' && e.data.type !== 'child' && e.data.type !== 'naver_up' && e.data.type !== 'kakao_up') {
              return
            }
            if(e.data.type === 'teacher'){
                //window.alert(e.data.payload);
                
                api.putDataRefresh("/member/modify",{
                    teacherValue:e.data.payload,
                    teacherDate:today(),
                },{},mbData).then(res=>{
                    if(res && res?.data?.status == "SUCCESS"){      
                        dispatch(
                            logOut()
                        );
                        dispatch(
                            open({
                                component: <PopupMain
                                    width={400}
                                    text={"선생님 인증이 완료되었습니다.\n\n다시 로그인 해주세요."}
                                    button="로그인"
                                    func="login"
                                    url={location.pathname}
                                />
                            })
                        );
                        setTeacherValue(today()?.substring(0,16)+" 인증 완료")
                    }else{

                    }
                });
                //setInfo({...info, techerValue:e.data.payload});
            }

            if(e.data.type === 'child'){
                const auths = JSON.parse(localStorage.getItem("sendData"));
                auths.TEL_NO = autoHypenPhone(auths.TEL_NO);
                setInfo({...info, phone:auths.TEL_NO, name:auths.RSLT_NAME});
                setAuth(auths);
            }

            if(e.data.type == 'kakao_up'){
                const kakao = e.data.payload;
             
                api.putDataRefresh("/member/modify",{
                    snsKakaoDate:today(),
                    snsKakao:kakao.id,
                    snsKakaoEmail:kakao.kakao_account.email
                },{
                },mbData).then(res=>{
                    setInfo({...info, snsKakao:kakao.id,snsKakaoDate:today(),snsKakaoEmail:kakao.kakao_account.email})
                });
              
            }else if(e.data.type == 'naver_up'){
                const naver = e.data.payload;
               
                api.putDataRefresh("/member/modify",{
                    snsNaverDate:today(),
                    snsNaver:naver.id,
                    snsNaverEmail:naver.email
                },{
                },mbData).then(res=>{



                    setInfo({...info, snsNaver:naver.id,snsNaverDate:today(),snsNaverEmail:naver.email})
                })
               
            }
             
        }
        window.addEventListener('message', childMessage);
        return () => {
            window.removeEventListener('message', childMessage);
        };
    },[]);

    const changePw = () => {
        dispatch(
            open({
                component: <PopupMain
                    text={"변경할 비밀번호를 입력해주세요."}
                    button="비밀번호 변경하기"
                    buttonCancel="취소"
                    leave=" leave"
                    naverType={info?.snsNaver !="" ? "naver" : ""}
                    func="pwchange"
                />,
            })
        );
    }
    const onTeacher = (e) => {
        console.log(process.env.REACT_APP_TEACHER_URL);
        window.open(process.env.REACT_APP_TEACHER_URL+"/teacher","","width=450,height=570")
    }
    const onSchool = () => {
        dispatch(
            open({
                component: <PopupSchool setInfo={setInfo} info={info} />,
            })
        );
    }
    const [mdl_tkn, SetMdlTkn] = useState("");
    const formRef = useRef(null);
    function onAuthChange(){
        
        api.getData("/auth/send", {}).then(res=>{
            SetMdlTkn(res.data);
            setTimeout(function(){
                window.open("", "auth_popup", "width=430,height=640,scrollbars=yes");
                formRef.current.cp_cd.value = "P08100000000";
                formRef.current.mdl_tkn.value = res.data;
                formRef.current.tc.value = "kcb.oknm.online.safehscert.popup.cmd.P931_CertChoiceCmd";
                formRef.current.action = "https://safe.ok-name.co.kr/CommonSvl"
                formRef.current.target = "auth_popup";
                formRef.current.submit();    
            },100)
        });
    }

    const onModify = (e) => {
        //필수 항목 체크
        if(!info?.school){
            dispatch(
                open({
                    component: <PopupMain 
                        text='학교명을 검색해주세요.'
                        button={'확인'}
                    />
                })
            );
            return;
        }
        if(info?.class1 ===0 && info?.class2 ===0 && info?.class3 ===0 ){
            dispatch(
                open({
                    component: <PopupMain 
                        text='담당학년을 하나 이상 선택해주세요.'
                        button={'확인'}
                    />
                })
            );
            return;
        }
        if(!info?.subject){
            dispatch(
                open({
                    component: <PopupMain 
                        text='담당과목을 작성해주세요.'
                        button={'확인'}
                    />
                })
            );
            return;
        }
        api.putDataRefresh("/member/modify",{
            email:info?.email,
            name:b64DecodeUnicode(info?.name),
            phone:b64DecodeUnicode(info?.phone),
            school:b64DecodeUnicode(info?.school),
            class1:(info?.class1 ? 1 : 0),
            class2:(info?.class2 ? 1 : 0),
            class3:(info?.class3 ? 1 : 0),                        
            subject:info?.subject, 
            area:info?.area,
            address:info?.address,
            marketingEmail:(info?.marketingEmail ? 1 : 0),
            marketingSms:(info?.marketingSms ? 1 : 0)
        },{},mbData).then(res=>{
            if(res && res?.data?.status == "SUCCESS"){       
                dispatch(
                    open({
                        component: <PopupMain 
                            text='변경되었습니다.'
                            button={'확인'}
                        />
                    })
                );

            }else{
                dispatch(
                    open({
                        component: <PopupMain 
                            text='필수 항목을 확인해주세요.'
                            button={'확인'}
                        />
                    })
                );
            }
        });
    }

    function goUrl(url) {
        navigate(url);
    }

    const btnSnsLogin = (snsType,id) => {
        if(snsType == 'naver'){
            dispatch(
                open({
                    component: <PopupMain 
                        text={'네이버 로그인 해제시 비밀번호를 다시 설정하셔야 합니다.\n변경하시겠습니까?'}
                        button={'확인'}
                        buttonCancel="취소"     
                        func="naverPwchange"                   
                    />
                })
            );
        } else {

            if(id){
                api.getDataRefresh("/member/sns",{
                    type:snsType
                },mbData).then(res=>{
                    if(res && res?.data?.status == "SUCCESS"){
                        if(snsType == "naver"){
                            setInfo({...info, snsNaver:"",snsNaverDate:"",snsNaverEmail:""});
                        }else{
                            setInfo({...info, snsKakao:"",snsKakaoDate:"",snsKakaoEmail:""});
                        }
                    }
                });
            }else{
    
            }
        }

    };


    return (
        <div className="changbi">
            

            <div className="wrap">
                <div className="info">
                    <h1 className="h1">내 정보 <button type="button" onClick={()=>goUrl('/my/leave')}>회원 탈퇴</button></h1>
                    
          

                    <h2 className="h2">기본 정보</h2>
                    <table className="basic">
                        <tbody>
                            <tr>
                                <th>회원유형</th>
                                <td>{info?.grade ? memberGrade[info?.grade] : '일반'}</td>
                            </tr>
                            {info?.grade == 0 || info?.grade == 1 ? 
                            <tr>
                                <th>선생님 인증</th>
                                <td className="td-flex">
                                    {info?.grade == 1 ? 
                                        info?.teacherDate?.substring(0,16)+" 인증 완료"
                                    :
                                        <>
                                        <span>{teacher_value}</span>
                                        {teacher_value === '선생님 인증을 완료해주세요.' ?
                                        <button type="button" onClick={(e)=>onTeacher(e)}>인증하기</button>
                                        :
                                        ''
                                        }
                                        
                                        </>
                                    }

                                </td>
                            </tr>
                            : ''}

                            <tr>
                                <th className="star">아이디</th>
                                <td><input type="text" value={info?.account} readOnly disabled/></td>
                            </tr>
                            <tr>
                                <th className="star">이메일</th>
                                <td><input type="text" value={info?.email} onChange={(e)=>setInfo({...info, email:e.target.value})} /></td>
                            </tr>
                            <tr>
                                <th className="star">비밀번호</th>
                                <td className="td-flex"><button type="button" onClick={()=>changePw()}>변경하기</button></td>
                            </tr>
                            <tr>
                                <th className="star">이름</th>
                                <td>
                                    <input type="text" value={b64DecodeUnicode(info?.name)}  readOnly/>
                                    <small>휴대폰 변경(인증)하시면 이름 변경이 가능합니다.</small>
                                </td>
                            </tr>
                            <tr>
                                <th className="star">휴대폰 번호</th>
                                <td className="td-flex">
                                    <form ref={formRef} method="post" >
                                        <input type="hidden" name="cp_cd" />
                                        <input type="hidden" name="mdl_tkn"/>
                                        <input type="hidden" name="tc"/>
                                    </form>                                    
                                    <input type="text" value={b64DecodeUnicode(info?.phone)}  readOnly/>
                                    <button type="button" onClick={()=>onAuthChange()}>변경하기</button>
                                </td>
                            </tr>
                            <tr>
                                <th >근무 지역</th>
                                <td className="td-flex">
                                    <input type="text"  value={(info?.area)} onChange={(e)=>setInfo({...info, area:e.target.value})} />
                                </td>
                            </tr>
                            <tr>
                                <th >학교 주소</th>
                                <td className="td-flex">
                                    <input type="text"  value={(info?.address)} onChange={(e)=>setInfo({...info, address:e.target.value})} />
                                </td>
                            </tr>
                            <tr>
                                <th className="star">학교명</th>
                                <td className="td-flex">
                                    <input type="text"  value={b64DecodeUnicode(info?.school)} readOnly disabled/>
                                    <button type="button" onClick={()=>onSchool()} >학교검색</button>
                                </td>
                            </tr>
                            <tr>
                                <th className="star">담당학년</th>
                                <td>
                                    <label className="check"><input type="checkbox" checked={info?.class1} onClick={(e)=>setInfo({...info, class1:!info?.class1})}/><span>1학년</span></label>
                                    <label className="check"><input type="checkbox" checked={info?.class2} onClick={(e)=>setInfo({...info, class2:!info?.class2})} /><span>2학년</span></label>
                                    <label className="check"><input type="checkbox" checked={info?.class3} onClick={(e)=>setInfo({...info, class3:!info?.class3})} /><span>3학년</span></label>
                                </td>
                            </tr>
                            <tr>
                                <th className="star">담당과목</th>
                                <td>
                                <input type="text" value={info?.subject} onChange={(e)=>setInfo({...info, subject:e.target.value})} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <h2 className="h2">SNS 계정 연동관리</h2>
                    <table className="sns">
                        <colgroup>
                            <col width="100"/>
                            <col width="*"/>
                            <col width="100"/>
                        </colgroup>
                        <thead>
                            <tr>
                                <th>SNS</th>
                                <th>연동여부</th>
                                <th>연결</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr className={info?.snsKakao ? '' : 'no'} >
                                <td className="text-center"><img src='/images/icon_kakao.svg' /></td>
                                <td>{info?.snsKakao ? <strong>{info?.snsKakaoDate?.substr(0,10).replaceAll("-",".")} 연결완료({info?.snsKakaoEmail})</strong> : '연결안됨'}</td>
                                <td className="text-center">
                                     {info?.snsKakao ? 
                                       <button type="button" onClick={()=>btnSnsLogin("kakao",info?.snsKakao)}>연결해제</button> 
                                     : 
                                        <KakaoLogin type="1"/>
                                     }
                                    
                                </td>
                            </tr>
                            <tr className={info?.snsNaver ? '' : 'no'} >
                                <td className="text-center"><img src='/images/icon_naver.svg' /></td>
                                <td>{info?.snsNaver ? <strong>{info?.snsNaverDate?.substr(0,10).replaceAll("-",".")} 연결완료({info?.snsNaverEmail})</strong> : '연결안됨'}</td>
                                <td className="text-center" style={{position:"relative"}}>
                                    
                                    {info?.snsNaver ? 
                                       <button type="button" onClick={()=>btnSnsLogin("naver",info?.snsNaver)}>연결해제</button> 
                                     : 
                                     <button type="button">연결하기<div style={{position: "absolute",opacity: 0,top: "5px"}}><NaverLogin type="1"/></div></button>
                                     }

                                    
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <h2 className="h2">마케팅 정보 수신</h2>
                    <table className="marketing">
                        <tbody>
                            <tr>
                                <th>창비교육의<br/>소식 받기</th>
                                <td>
                                    <strong>다양한 이벤트 및 창비교육의 새소식에 대한 안내를 받아보실 수 있습니다.</strong>
                                    <div>
                                        <label className="check"><input type="checkbox"  checked={info?.marketingSms} onClick={(e)=>setInfo({...info, marketingSms:!info?.marketingSms})}/><span>SMS수신동의</span></label>
                                        <label className="check"><input type="checkbox"  checked={info?.marketingEmail} onClick={(e)=>setInfo({...info, marketingEmail:!info?.marketingEmail})}/><span>메일수신동의</span></label>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="info-btn">
                        <button type="button" onClick={(e)=>onModify(e)}>개인정보 수정</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
