const exportData = {
    main: "/",
    login: "/login",
    register01: "/agree",
    register02: "/register",
    find: "/find",
    modal: "/modal",
    curriculum: "/curriculum",
    innovation: "/innovation",
    calendar: "/calendar",
    lesson: "/lesson",
    month: "/month",
    dictionary: "/dictionary",
    dictionary1: "/dictionary1",
    dictionary2: "/dictionary2",
    creative: "/creative",
    art: "/art",
    literature: "/literature",
}

export default exportData;