import React, { useEffect, useState, useRef } from 'react';
import { Routes, useNavigate, useLocation, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { open, close } from '../redux/popupSlice';
import { logIn, logOut  } from '../redux/usersSlice';
import PopupMain from '../pages/popup/PopupMain';
import images from "../libs/images";
import Paging from "../components/paging";
import * as api from '../libs/api';
import { imageUrl } from "../libs/utils";
import { useCookies } from 'react-cookie';

import Pagination from 'react-js-pagination';

export default function List(props) {
    const [cookies, setCookie, removeCookie] = useCookies(["access"]);
    const { mbData, accessToken, autoLogin } = useSelector(s => s.usersReducer);

    const { title, comment, link ,type, category_seq} = props;


    const dispatch = useDispatch();
    
    const [list, setList] = useState([]);
    let [search_text, setSearchText] = useState("");
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(1);
    let [limit, setLimit] = useState(9);

    const location = useLocation();
    const navigate = useNavigate();
    
    function goUrl(url) {
        navigate(url);
    }

    const onList = (page) => {       
        localStorage.setItem("page",page);
        api.getData("/board/"+category_seq ,{
            page:page-1,
            size:limit,
            text:search_text
        }).then(res=>{

            if(res && res?.data?.status=="SUCCESS"){
                setList(res.data.data.content);
                setPage(page-1);
                setTotal(res.data.data.totalElements);                   
            }
        });
    }
        
    const onSearch = () => {
        onList(1);
    }

    useEffect(() => {
        search_text="";
        setSearchText("");
        if(type){
            limit = 16;
            setLimit(16);
        }
        onList(location?.state ? location?.state : 1); 
    }, [type, category_seq]);
    return (
         
            <div className="box">
                <h1 className="title">{title}
                    <div className="search">
                        <input type="text" onChange={(e)=>setSearchText(e.target.value)} value={search_text}/>
                        {search_text ? 
                        <button type="button" onClick={(e)=>setSearchText("")} className="text-remove"><img src='/images/close-circle-fill.svg' /></button>
                        :
                        <></>
                        }
                        <button type="button" onClick={(e)=>onSearch(e)}><img src='/images/icon_search.svg' /></button>
                    </div>
                </h1>
                <p className='comment'>{comment}</p>
                {(type === 1) ?
                <div className="list-type4">
                    <ul>
                        {list.map((val2) => (
                        <li>
                            <Link to={link+"/"+val2.boardSeq} state={{page:(page+1),link:location.pathname}}>
                            <img src={imageUrl+"board?file="+val2.thumbnail} width="100%" />
                            <h2>{val2.title}</h2>
                            <p>
                            {val2.tag && 
                                val2.tag.split(",").map(x=>
                                    (<small>#{x.trim()}</small>)
                            )}
                            </p>
                            </Link>
                        </li>
                        ))}
                    </ul>
                </div>                
                : (type === 2) ?
                <div className="list-type1">
                    <ul>
                        {list.map((val2) => (
                        <li>
                            <Link to={link+"/"+val2.boardSeq} state={{page:(page+1),link:location.pathname}}>
                            <img src={imageUrl+"board?file="+val2.thumbnail} width="100%"  />
                            <h2>{val2.title}</h2>
                            </Link>
                        </li>
                        ))}
                    </ul>
                </div>
                :
                <div className="list-type1">

                    <ul>
                        {list.map((val2) => (
                        <li>
                            <Link to={link+"/"+val2.boardSeq} state={{page:(page+1),link:location.pathname}}>
                            <img src={imageUrl+"board?file="+val2.thumbnail} width="100%" height="272" />

                            <p>
                            {val2.tag && 
                                val2.tag.split(",").map(x=>
                                    (<small>#{x.trim()}</small>)
                            )}
                            </p>
                            <h2>{val2.title}</h2>
                            </Link>
                        </li>
                        ))}
                    </ul>
                </div>
                }
                {list.length <= 0 &&
                    <p className="nolist">작성된 글이 없습니다.</p>
                }
                {list.length > 0 &&
                <div className="paging">
                    <Pagination
                        activePage={page+1}
                        itemsCountPerPage={limit}
                        totalItemsCount={total}
                        pageRangeDisplayed={10}
                        itemClassFirst="page_first"
                        itemClassPrev="page_prev"
                        itemClassNext="page_next"
                        itemClassLast="page_last"
                        onChange={onList}
                    />
                </div>
                }
            </div>

    );
}
