import React, { useEffect, useState, useRef, forwardRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import images from "../../libs/images";

import routes from "../../libs/routes";

import { logIn, logOut } from '../../redux/usersSlice';
import { open, close } from '../../redux/popupSlice';
import { loadingStart, loadingEnd } from '../../redux/loadingSlice';

import BtnScrap from '../../components/btn_scrap';
import BtnFileDownload from '../../components/file_download';
import * as api from '../../libs/api';

export default function PopupCategoryData(props) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [list, setList] = useState([]);

    useEffect(() => {

        api.getData("/curriculum" ,{
            page:0,
            size:9999,
            unitSeq:0,
            unitLevelSeq:0,
            typeSeq:0,
            typeLevelSeq:0,
            subjectSeq:props.subjectInfo.subjectSeq
        }).then(res=>{
            if(res && res?.data?.status=="SUCCESS"){
                const ret = res.data.data
                setList(ret.curriculumList.content);
            }
        });
      
    }, []);
    const tailName = (filename) => {
        const tail = filename.split(".");
        return tail[tail.length-1].trim();
    }
    return (
        <>
            <div className="changbi pop _02 _04">
                <h1>
                    유형별 자료
                    <button type="button" onClick={() => dispatch(close())}><img src='/images/exit-icon.svg' /></button>
                </h1>
                <div className="form flex">
                    {props.typeList.map(par => (
                    <div className="list">
                        <dl>
                            <dt>{par.name}</dt>
                        </dl>
                        <dl className="scroll">
                            {list.map(val => val.typeSeq === par.typeSeq && (
                            <dd>
                                {val?.fileOri ? <img src={'/images/'+tailName(val?.fileOri)+'.svg'} className="cimg"/> : <></> }
                                <strong>{val.name}</strong>
                                <div>
                                { val?.fileOri ? 
                                <BtnFileDownload folder="data" filename={val?.file.trim()} filename_original={val?.fileOri.trim()} />
                                : 
                                <></>
                                }
                                </div>
                            </dd>
                            ))}
                        </dl>
                    </div>
                    ))}

                </div>
            </div>
        </>
    );
}
