import React, { useEffect, useState, useRef } from 'react';

import { Routes, useNavigate, useLocation, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { open, close } from '../../redux/popupSlice';
import { logIn, logOut  } from '../../redux/usersSlice';
import images from "../../libs/images";
import PopupMain from '../popup/PopupMain';
import * as api from '../../libs/api';

export default function Leave() {
    const [agree, setAgree] = useState(false);

    const location = useLocation(); 
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { mbData, accessToken, autoLogin } = useSelector(s => s.usersReducer);

    useEffect(() => {
   
        api.getDataRefresh("/member", {
            userSeq:mbData.userSeq
        },mbData).then(res=>{

            if(res && res?.data?.status=="SUCCESS"){
                
            }else{
                localStorage.removeItem("access");
                localStorage.removeItem("remail");
                localStorage.removeItem("page");
                localStorage.removeItem("sendData");
                dispatch(
                    logOut()
                );
                navigate("/login");  
            }
        });
    });
    const onLeave = () => {
        if(agree){
            dispatch(
                open({
                    component: <PopupMain
                        text={"회원확인을 위해 비밀번호를 입력하세요."}
                        button="탈퇴하기"
                        buttonCancel="취소"
                        leave=" leave"
                        func="leave"
                    />,
                })
            );
        }else{
            dispatch(
                open({
                    component: <PopupMain
                        width={400}
                        text="회원 탈퇴 약관을 선택해주세요."
                        button="닫기"
                    />
                })
            );
        }
    }


    return (
        <div className="changbi">
            <div className="wrap">
                <div className="info leave">
                    <h1 className="title">회원 탈퇴</h1>
                    <div className="out">
                        이 헌법중 공무원의 임기 또는 중임제한에 관한 규정은 이 헌법에 의하여 그 공무원이 최초로 선출 또는 임명된 때로부터 적용한다. 모든 국민은 양심의 자유를 가진다. 재의의 요구가 있을 때에는 국회는 재의에 붙이고, 재적의원과반수의 출석과 출석의원 3분의 2 이상의 찬성으로 전과 같은 의결을 하면 그 법률안은 법률로서 확정된다.
                        국회의원이 회기전에 체포 또는 구금된 때에는 현행범인이 아닌 한 국회의 요구가 있으면 회기중 석방된다. 법관은 탄핵 또는 금고 이상의 형의 선고에 의하지 아니하고는 파면되지 아니하며, 징계처분에 의하지 아니하고는 정직·감봉 기타 불리한 처분을 받지 아니한다. 대통령은 조약을 체결·비준하고, 외교사절을 신임·접수 또는 파견하며, 선전포고와 강화를 한다.
                        이 헌법중 공무원의 임기 또는 중임제한에 관한 규정은 이 헌법에 의하여 그 공무원이 최초로 선출 또는 임명된 때로부터 적용한다. 모든 국민은 양심의 자유를 가진다. 재의의 요구가 있을 때에는 국회는 재의에 붙이고, 재적의원과반수의 출석과 출석의원 3분의 2 이상의 찬성으로 전과 같은 의결을 하면 그 법률안은 법률로서 확정된다.
                        국회의원이 회기전에 체포 또는 구금된 때에는 현행범인이 아닌 한 국회의 요구가 있으면 회기중 석방된다. 법관은 탄핵 또는 금고 이상의 형의 선고에 의하지 아니하고는 파면되지 아니하며, 징계처분에 의하지 아니하고는 정직·감봉 기타 불리한 처분을 받지 아니한다. 대통령은 조약을 체결·비준하고, 외교사절을 신임·접수 또는 파견하며, 선전포고와 강화를 한다.
                    </div>
                    <label className="check"><input type="checkbox" onClick={()=>setAgree(!agree)} /><span>회원 탈퇴 약관을 충분히 확인했으며, 이에 동의함.</span></label>
                    <div className="button">
                        <button type="button" onClick={()=>onLeave()}>서비스 탈퇴하기</button>
                        <button type="button" onClick={()=>navigate(-1)}>서비스 계속 이용하기</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
