import React, { useEffect, useState, useRef, forwardRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import images from "../../libs/images";
import routes from "../../libs/routes";

import { logIn, logOut } from '../../redux/usersSlice';
import { open, close } from '../../redux/popupSlice';
import { loadingStart, loadingEnd } from '../../redux/loadingSlice';

import BtnScrapAll from "../../components/btn_scrap_all";
import BtnFileDownloadAll from "../../components/file_download_all";
import * as api from '../../libs/api';

export default function PopupDownload(props) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [list, setList] = useState([]); 
    const [checkItems, setCheckItems] = useState([]);


    const onAllChecked = (e) => {

        if(e.target.checked){
            const idArray = [];
            list.forEach((el) => idArray.push(el.curriculumSeq));
            setCheckItems(idArray);
        } else {
            setCheckItems([]);
        }

            
        setList(
            list.map(par=>
               par.curriculumSeq > 0 ? { ...par, checked: e.target.checked } : par
            )
        )
    }

    const onChecked = (e,val) => {
        if( e.target.checked ){
            setCheckItems(prev => [...prev, val.curriculumSeq]);
        }else{
            setCheckItems(checkItems.filter((el) => el !== val.curriculumSeq));
        }

        setList(
            list.map(par=>
                par.curriculumSeq === val.curriculumSeq ? { ...par, checked: !val.checked } : par
            )
        )
    }
    useEffect(() => {

        api.getData("/curriculum/common" ,{
            page:0,
            size:9999,
            subjectSeq:props.subjectInfo.subjectSeq,
            typeLevelSeq:0,
            type:props.type
        }).then(res=>{
            if(res && res?.data?.status=="SUCCESS"){
                const ret = res.data.data
                setList(ret.curriculumList.content);   
            }
        });
        
    }, []);
    
    const tailName = (filename) => {
        const tail = filename.split(".");
        return tail[tail.length-1].trim();
    }
    return (
        <>
            <div className="changbi pop _02">
                <h1>
                    파일 다운로드
                    <button type="button" onClick={() => dispatch(close())}><img src='/images/exit-icon.svg' /></button>
                </h1>
                <div className="form">
                    <div className="list">
                        <dl>
                            <dt>
                                <label className="check"><input type="checkbox" onChange={(e)=>onAllChecked(e)} checked={checkItems.length === list.length && list.length > 0 ? true : false}/></label>
                                파일이름
                            </dt>
                        </dl>
                        <dl className="scroll">
                            {list.map((val) => (
                            <dd>
                                <label className="check"><input type="checkbox"  checked={checkItems.includes(val.curriculumSeq) ? true : false} onChange={(e)=>onChecked(e,val)}/></label>
                                {val?.fileOri ? <img src={'/images/'+tailName(val?.fileOri)+'.svg'} /> : <></> }
                                <span>{val.name}</span>
                            </dd>
                            ))}

                        </dl>
                    </div>
                    <div className="popup-btn">
                        {/* type=2 교과관리 */}
                        <BtnScrapAll list={list} type="2"  />
                        <BtnFileDownloadAll folder="data" list={list} name="file" ori="fileOri"/>
                        <BtnFileDownloadAll folder="data" list={list} name="file" ori="fileOri" type="1"/>                        
                    </div>
                </div>
            </div>
        </>
    );
}
