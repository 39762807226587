import { createSlice } from '@reduxjs/toolkit';

const popupSlice = createSlice({
  name: 'popup',
  initialState: {
    open: false,
    onCancelPress: "",
    onPress: "",
    message: "",
    title: "",
    button: "",
    buttonCencle: "",
    component: "",
  },
  reducers: {
    open(state, action) {
        state.open = true;
        state.onCancelPress = action.payload.onCancelPress;
        state.onPress = action.payload.onPress;
        state.message = action.payload.message;

        if(action.payload.title) state.title = action.payload.title;
        if(action.payload.button) state.button = action.payload.button;
        if(action.payload.buttonCencle) state.buttonCencle = action.payload.buttonCencle;
        if(action.payload.component) state.component = action.payload.component;
    },
    close(state) {
        state.open = false;
        state.onCancelPress = "";
        state.onPress = "";
        state.message = "";
        state.title = "";
        state.button = "";
        state.buttonCencle = "";
        state.component = "";
    },
  },
});

export const {
    open,
    close,
} = popupSlice.actions;

export default popupSlice.reducer;
