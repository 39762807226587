import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { Provider } from 'react-redux';
import store, { persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';

import Popup from './redux-components/Popup';
import Loading from './redux-components/Loading';

import Layout from './Layout';

import './App.css';
import './css/layout.css';
import './css/common.css';
import './css/media.css';
import './css/changbi.css';
import './css/suneditor.css';


function App() {

  /* 빌드시 주석해제 */
  /* console = {};
  console.log = function(){};
  console.warn = function(){};
  console.error = function(){}; */
  
  return (
    <div className="App">
      <BrowserRouter>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
              <Layout />
              <Popup />
              <Loading />
          </PersistGate>
        </Provider>
      </BrowserRouter>
    </div>
  );
}

export default App;
