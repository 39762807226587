import React, { useEffect, useState, useRef } from 'react';
import { Routes, useNavigate, useLocation, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { open, close } from '../../redux/popupSlice';
import { logIn, logOut, setData as setUserData  } from '../../redux/usersSlice';
import images from "../../libs/images";
import PopupMain from "../popup/PopupMain";
import PopupMyBook from "../popup/PopupMyBook";
import * as api from '../../libs/api';
import Pagination from 'react-js-pagination';
import { imageUrl } from "../../libs/utils";



export default function Book() {
    const { mbData, accessToken, refreshToken, autoLogin,mbBook } = useSelector(s => s.usersReducer);
    const location = useLocation(); 
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [list, setList] = useState([]);
    const [page, setPage] = useState(0);
    const [page_range, setPageRagne] = useState(0);
    const [total, setTotal] = useState(1);
    const [limit, setLimit] = useState(10);
    
    useEffect(() => {

        if(!mbData.userSeq){
            localStorage.removeItem("access");
            localStorage.removeItem("remail");
            localStorage.removeItem("page");
            localStorage.removeItem("sendData");
            dispatch(
                logOut()
            );
            dispatch(
                open({
                    component: <PopupMain
                        width={400}
                        text="로그인이 필요한 서비스입니다."
                        button="로그인"
                        buttonCancel="닫기"
                        func="login"
                        url={location.pathname}
                    />,
                    
                })
            );
            navigate("/login");
        }else{
            onList();
        }

    },[]);

    const onList = () =>{

        /*
        api.getDataRefresh("/book", {
            memberSeq:mbData.userSeq
        },mbData).then(res=>{

            if(res && res?.data?.status=="SUCCESS"){
                setList(res.data.data);               
            }else{
                localStorage.removeItem("access");
                localStorage.removeItem("remail");
                localStorage.removeItem("page");
                localStorage.removeItem("sendData");
                dispatch(
                    logOut()
                );
                dispatch(
                    open({
                        component: <PopupMain
                            width={400}
                            text="로그인이 필요한 서비스입니다."
                            button="로그인"
                            buttonCancel="닫기"
                            func="login"
                            url={location.pathname}
                        />,
                    })
                );  
                navigate("/login");  
            }
        });
        */
    }

    const onRemove = (seq) => {

        dispatch(
            open({
                component: <PopupMain 
                    text='내 교과서에서 삭제하시겠습니까?'
                    button={'네'}
                    buttonCancel={'닫기'}
                    func="book"
                    seq={seq}
                    list={list}
                    setList={setList}
                />
            })
        );
    }

    const onBook = (seq) => {
        dispatch(
            open({
                component: <PopupMyBook list={list} setList={setList} />
            })
        );
    }
    const goLink = (val) => {
        let link = 0;
        if(val.subhect=="국어"){
            link = 0;
        }else if(val.subhect=="사회"){
            link = 1;
        }else if(val.subhect=="미술"){
            link = 2;
        }
        return "/curriculum/"+link+"/"+val.subjectSeq;
    }
      
    return (
        <div className="changbi">
            <div className="wrap">
                <div className="my">
                    <h1 className="title">내 교과서<button type="button" onClick={()=>onBook()}><img src='/images/add-line.svg' />내 교과서</button></h1>
                    {mbBook.length > 0 ? 
                    <ul>
                        {mbBook.map(val=>(
                        <li>
                            <Link to={goLink(val)}>
                                <img src={imageUrl+"subject?file="+val.subjectThumbnail} height="80" width="64"/>
                                <div>
                                    <span>{val.school} | {val.year}년 개정</span>
                                    <h2>{val.subjectName}</h2>
                                </div>
                            </Link>
                            <button type="button" onClick={()=>onRemove(val)}><img src='/images/icon_remove.svg' /></button>
                        </li>
                        ))}
                    </ul>
                    :
                    <p className="no-data">등록된 교과서가 없습니다.<br/>선생님의 교과서를 설정해 보세요.</p>
                    }
                </div>

            </div>
        </div>
    );
}
