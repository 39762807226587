import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import images from '../../libs/images';
import routes from '../../libs/routes';
import * as api from '../../libs/api';

import { useDispatch, useSelector } from 'react-redux';
import { open, close } from '../../redux/popupSlice';
import Popup01 from '../popup/Popup01';


export default function Auth() {
    const [searchParams, setSearchParams] = useSearchParams();

    const { status } = useParams();
    const statusList = ['success', 'fail', 'cancel'];

    
    useEffect(() => {
        api.getData("/auth/receive", {
            mdl_tkn:searchParams.get("mdl_tkn")
        }).then(res=>{
            localStorage.setItem("sendData",JSON.stringify(res.data));
            window.opener.postMessage({ type: 'agree', payload: res.data }, '*')
            window.close();
        });
    },[]);


    return (
        <div className="login_back">
          
        </div>
    );
}