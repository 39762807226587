import React, { useEffect, useState, useRef } from 'react';
import { Routes, useNavigate, useLocation, Link,useParams } from "react-router-dom";
import images from "../../libs/images";
import InnerMenu from "../../components/innovation_menu";
import Pagination from 'react-js-pagination';
import * as api from '../../libs/api';
import { imageUrl } from "../../libs/utils";
import { current } from '@reduxjs/toolkit';

export default function Page() {
    
    const { type, id } = useParams();
    const [tab, setTab] = useState(1);
    const months = [1,2,3,4,5,6,7,8,9,10,11,12];
    const [chk, setChk] = useState(0);

    const [list, setList] = useState([]);
    const [page, setPage] = useState(0);
    const [page_range, setPageRagne] = useState(0);
    const [total, setTotal] = useState(1);
    const [limit, setLimit] = useState(8);
    const [title, setTitle] = useState(['국어','사회','미술']);

    const date = new Date()
    let current_month = date.getMonth() + 1;
    
    const [month, setMonth] = useState(current_month);
    const [school, setSchool] = useState("");

    const handleResize = () => {
        if(window.innerWidth >= 800){
            setPageRagne(10);
        } else {
            setPageRagne(5);
        }
    };
    
    const changeMonth = (month) => {
        setMonth(month);
        onList(1,month,school);
    }
    const changeSchool = (school) => {
        setSchool(school);
        onList(1,month,school);
    }

    useEffect(() => {
        
        setSchool("");
        if(location?.state?.school){
            setSchool(location?.state?.school);
        }
        
        if(location?.state?.month > 0){
            current_month = location?.state?.month-1;
        }  


        onList(1,current_month,"");
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    },[type])

    const onList = (page,month,school) => {
        api.getData("/calendar" ,{
            subject:title[type],
            school:school,
            month:month ? month : 0,
            page:page-1,
            size:limit
        }).then(res=>{
            if(res?.headers?.get("New-Access-Token")){
                localStorage.setItem("access", res.headers.get("New-Access-Token"));
            }
            if(res && res?.data?.status=="SUCCESS"){
                setList(res.data.data.content);
                setPage(page-1);
                setTotal(res.data.data.totalElements);         
                
                if(res?.data?.data?.content?.length > 0) {
                    setMonth(res?.data?.data?.content[0]['month']);
                }
            }
        });
    }

    const location = useLocation();
    const navigate = useNavigate();
    
    function goUrl(seq) {
        navigate(location.pathname+"/"+seq)
    }

    return (
        <div className="changbi">
            <div className="wrap">
                <InnerMenu m_title="수업 혁신"/>
                <div className="box">
                    <h1 className="title">{title[type]} 수업</h1>
                    <div className="category">
                        <div>
                            <label>구분</label>
                            <button type="button" className={school==='' ? 'active' : ''} onClick={(e)=>changeSchool("")}>전체</button>                            
                            <button type="button" className={school==='중학' ? 'active' : ''} onClick={(e)=>changeSchool("중학")}>중학</button>
                            <button type="button" className={school==='고등' ? 'active' : ''} onClick={(e)=>changeSchool("고등")}>고등</button>
                            
                        </div>
                        <div>
                            <label>월별</label>
                            <button type="button" className={month===0 ? 'active' : ''} onClick={(e)=>changeMonth(0)}>전체</button>
                            {months.map((val) => (
                            <button type="button" className={month===val ? 'active' : ''} onClick={(e)=>changeMonth(val)}>{val}월</button>
                            ))}
                        </div>                        
                    </div>
                    <div className="list-type2">
                        <div className="total">총<strong>{total}</strong>건</div>
                        <ul>
                            {list.map((val2) => (
                            <li onClick={()=>navigate("/month/"+type+"/"+val2.innovationSeq,{state:{link:location.pathname, month:month, school:school}})}>
                                <img src={imageUrl+"dictionary?file="+val2.fileThumbnail} width="100%" />

                            </li>
                            ))}
                        </ul>
                        {list.length > 0 ?
                        <></>
                        :
                        <p className="no-data">내용이 없습니다.</p>
                        }
                    </div>
                    {list.length > 0 ?
                    <div className="paging">
                        <Pagination
                            activePage={page+1}
                            itemsCountPerPage={limit}
                            totalItemsCount={total}
                            pageRangeDisplayed={page_range}
                            itemClassFirst="page_first"
                            itemClassPrev="page_prev"
                            itemClassNext="page_next"
                            itemClassLast="page_last"
                            onChange={onList}
                        />
                    </div>
                    :
                    <></>
                    }
                </div>
            </div>
        </div>
    );
}
