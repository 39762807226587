import youtube_icon from '../assets/images/youtube-icon.svg';
import blog_icon from '../assets/images/blog-icon.svg';
import star_line from '../assets/images/star-line.svg';
import down_arrow from '../assets/images/Down_Arrow_5.svg'; //
import main_logo from '../assets/images/main-logo.svg';
import checked1 from '../assets/images/checked1.svg'; //
import subject_img1 from '../assets/images/subject-img1.svg';
import subject_img2 from '../assets/images/subject-img2.svg';
import subject_img3 from '../assets/images/subject-img3.svg';
import short1 from '../assets/images/short1.svg';
import short2 from '../assets/images/short2.svg';
import short3 from '../assets/images/short3.svg';
import light from '../assets/images/light.svg';
import learning from '../assets/images/learning.svg';
import book from '../assets/images/book.svg';
import event_img1 from '../assets/images/event-img1.svg';
import event_img2 from '../assets/images/event-img2.svg';
import update_left from '../assets/images/update-left.svg';
import update_right from '../assets/images/update-right.svg';
import service_call from '../assets/images/service-call.svg';
import top_close from '../assets/images/top_close.svg';
import menu_line from '../assets/images/menu-line.svg';
import pause from '../assets/images/pause.svg';
import play from '../assets/images/play.svg';
import quick_login from '../assets/images/quick-login.svg';
import quick_logout from '../assets/images/quick-logout.svg';
import quick_myclass from '../assets/images/quick-myclass.svg';
import quick_mybook from '../assets/images/quick-mybook.svg';
import quick_classbook from '../assets/images/quick-classbook.svg';
import quick_up from '../assets/images/quick-up.svg';
import quick_chat from '../assets/images/quick-chat.svg';
import quick_myclass_white from '../assets/images/quick-myclass-white.svg';
import quick_mybook_white from '../assets/images/quick-mybook-white.svg';
import quick_classbook_white from '../assets/images/quick-classbook-white.svg';
import quick_up_white from '../assets/images/quick-up-white.svg';
import quick_chat_white from '../assets/images/quick-chat-white.svg';
import arrow_right_white from '../assets/images/arrow-right-white.svg';
import arrow_left_white from '../assets/images/arrow-left-white.svg';
import banner_main from '../assets/images/banner_main.png';
import banner_main_mobile from '../assets/images/banner_main_mobile.svg';
import x_icon_white from '../assets/images/x-icon-white.svg';
import check_navy from '../assets/images/check-navy.svg';
import move_top_arrow from '../assets/images/move-top-arrow.svg';
import service_btn_right from '../assets/images/service-btn-right.svg';
import test_book from '../assets/images/test-book.svg';
import input_check from '../assets/images/input_check.svg';
import input_view from '../assets/images/pw_view_on.svg';
import input_view_off from '../assets/images/pw_view_off.svg';
import button_x from '../assets/images/exit-icon.svg';
import no_book_image from '../assets/images/no_book_image.svg';
import icon_ebook from '../assets/images/icon-ebook.svg';
import icon_dvd from '../assets/images/icon-dvd.svg';
import icon_test from '../assets/images/icon-test.svg';
import button_plus from '../assets/images/add-line.svg';
import icon_download from '../assets/images/icon-download.svg';
import icon_ppt from '../assets/images/icon-ppt.svg';
import button_download from '../assets/images/button_download.svg';
import button_download2 from '../assets/images/button_download2.svg';
import button_search from '../assets/images/button_search.svg';
import icon_folder from '../assets/images/icon_folder.svg';
import ex_banner from '../assets/images/ex_banner.png';
import ex_banner1 from '../assets/images/ex_banner1.png';
import ex_img1 from '../assets/images/ex_img1.png';
import icon_inno1 from '../assets/images/icon_inno1.svg';
import icon_inno2 from '../assets/images/icon_inno2.svg';
import icon_inno3 from '../assets/images/icon_inno3.svg';
import paging_nnext from '../assets/images/paging_nnext.svg';
import paging_next from '../assets/images/paging_next.svg';
import paging_pprev from '../assets/images/paging_pprev.svg';
import paging_prev from '../assets/images/paging_prev.svg';
import ex_img2 from '../assets/images/ex_img2.png';
import icon_search from '../assets/images/icon_search.svg';
import ex_img3 from '../assets/images/ex_img3.png';
import ex_img4 from '../assets/images/ex_img4.png';
import icon_all from '../assets/images/icon-all.svg';
import icon_open from '../assets/images/icon-open.svg';
import icon_notice from '../assets/images/icon_notice.png';
import home_navi from '../assets/images/home_navi.svg';
import ex_img5 from '../assets/images/ex_img5.png';
import icon_remove from '../assets/images/icon_remove.svg';
import icon_trash from '../assets/images/icon_trash.svg';
import qna from '../assets/images/qna.svg';
import icon_qna from '../assets/images/icon_qna.svg';
import remove from '../assets/images/remove.svg';
import icon_kakao from '../assets/images/icon_kakao.svg';
import icon_naver from '../assets/images/icon_naver.svg';
import ex_img6 from '../assets/images/ex_img6.png';
import ex_img7 from '../assets/images/ex_img7.png';
import m_close from '../assets/images/m_close.svg';
import popup from '../assets/images/popup.svg';
import input_remove from '../assets/images/close-circle-fill.svg';


const exportData = {
    youtube_icon,
    blog_icon,
    star_line,
    down_arrow,
    main_logo,
    checked1,
    subject_img1,
    subject_img2,
    subject_img3,
    short1,
    short2,
    short3,
    light,
    learning,
    book,
    event_img1,
    event_img2,
    update_left,
    update_right,
    service_call,
    top_close,
    menu_line,
    pause,
    play,
    quick_login,
    quick_myclass,
    quick_mybook,
    quick_classbook,
    quick_up,
    quick_chat,
    quick_myclass_white,
    quick_mybook_white,
    quick_classbook_white,
    quick_up_white,
    quick_chat_white,
    arrow_right_white,
    arrow_left_white,
    banner_main,
    banner_main_mobile,
    x_icon_white,
    check_navy,
    move_top_arrow,
    quick_logout,
    service_btn_right,
    test_book,
    input_check,
    input_view,
    input_view_off,
    button_x,
    no_book_image,
    icon_ebook,
    icon_dvd,
    icon_test,
    button_plus,
    icon_download,
    icon_ppt,
    button_download,
    button_download2,
    button_search,
    icon_folder,
    ex_banner,
    ex_banner1,
    ex_img1,
    icon_inno1,
    icon_inno2,
    icon_inno3,
    paging_nnext,
    paging_next,
    paging_pprev,
    paging_prev,
    ex_img2,
    icon_search,
    ex_img3,
    ex_img4,
    icon_all,
    icon_open,
    icon_notice,
    home_navi,
    icon_remove,
    ex_img5,
    icon_trash,
    qna,
    icon_qna,
    remove,
    icon_kakao,
    icon_naver,
    ex_img6,
    ex_img7,
    m_close,
    popup,
    input_remove
}

export default exportData;