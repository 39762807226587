import React, { useEffect, useState, useRef } from 'react';
import { Routes, useNavigate, useLocation, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { open, close } from '../redux/popupSlice';
import { logIn, logOut  } from '../redux/usersSlice';
import images from "../libs/images";
import * as api from '../libs/api';
import PopupMain from '../pages/popup/PopupMain';


export default function BtnScrapAll(props) {

    const location = useLocation();
    const dispatch = useDispatch();
    const { mbData, accessToken, autoLogin } = useSelector(s => s.usersReducer);
    
    const onAllScrap = () => {

        if( !mbData?.userSeq){
            localStorage.removeItem("access");
            localStorage.removeItem("remail");
            localStorage.removeItem("page");
            localStorage.removeItem("sendData");
            dispatch(
                logOut()
            );
            dispatch(
                open({
                    component: <PopupMain
                        width={400}
                        text="로그인이 필요한 서비스입니다."
                        button="로그인"
                        buttonCancel="닫기"
                        func="login"
                        url={location.pathname}
                    />
                })
            );
            return
        }

        if( props?.list?.filter(val=>val.checked).length <= 0 ){
            dispatch(
                open({
                    component: <PopupMain
                        width={400}
                        text="스크랩할 항목을 선택해 주세요."
                        button="닫기"
                    />
                })
            );
            return
        }

        props.list.map(val=>{
            val.checked && (
                api.postDataRefresh("/scrap" ,onParam(val,props.type),mbData).then(res=>{ 
                    
                    if(res && res?.data?.status=="SUCCESS"){
                        
                    } 
                })
            )
        });
        dispatch(
            open({
                component: <PopupMain
                    width={400}
                    text={"스크랩에 추가되었습니다.\n나의 교실에서 확인해주세요."}
                    button="닫기"
                />,
            })
        );
    }
    const onParam = (val,type) => {
        let parm = {};
        if(type==1){
            //수업고민사전
            parm = {
                type:1,
                categorySeq:0,
                categoryName:"수업 혁신",
                typeSeq:val.dictionarySeq,
                title:val.name,
                category:"수업고민사전",
                memberSeq:mbData.userSeq,
                link:location.pathname,
                file:val.addFile,
                fileOri:val.addFileOri
            };
        }else if(type==2){
            parm = {
                type:2,
                categorySeq:val.subjectSeq,
                categoryName:val.subject,
                typeSeq:val.curriculumSeq,
                title:val.name,
                category:val.subjectText,
                memberSeq:mbData.userSeq,
                link:location.pathname,
                file:val.file,
                fileOri:val.fileOri
            };
        }else if(type==4){
            //게시판 창의적, 연계도서 선생님맞춤
            parm = {
                type:4,
                categorySeq:val.categorySeq,
                categoryName:(val.type == 0 ? '창의적 체험 활동' : val.type == 1 ? '수업 연계 도서' : '선생님 맞춤 자료'),
                typeSeq:val.boardSeq,
                title:val.title,
                category:val.category,
                memberSeq:mbData.userSeq,
                link:location.pathname,
                file:val.file,
                fileOri:val.oriFile
            };
        }else{
            //이달의 수업
            parm = {
                type:0,
                categorySeq:0,
                categoryName:"수업 혁신",
                typeSeq:val.innovationSeq,
                title:val.title,
                category:"이달의 수업",
                memberSeq:mbData.userSeq,
                link:location.pathname,
                file:val.fileZip3,
                fileOri:val.fileZipOri3
            };
        }

        return parm;
    }

    return (
        <button type="button" onClick={(e)=>onAllScrap(e)}><img src='/images/icon_folder.svg' width="20" />담기</button>
    );
}
