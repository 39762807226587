import React, { useEffect, useState, useRef } from 'react';
import { Routes, useNavigate, useLocation, Link } from "react-router-dom";

import * as api from '../../libs/api';
import InnerMenu from "../../components/innovation_menu";

export default function Banner(props) {

    const location = useLocation();
    const navigate = useNavigate();

    return (
        <div className="changbi">
            <div className="wrap">
                <InnerMenu m_title="수업 혁신"/>        
                <div className="box dict-main">
                    <div className="dict1">
                        <div>
                        <img src="/images/dictionary_banner.jpg" width="100%"/>
                        </div>
                        {/*
                        <div>
                            <p>
                                <h1>수업 고민 사전</h1>
                                {window.innerWidth >= 640 && 
                                    <>개념 설명부터 다양한 활동 제안까지</>
                                }
                                </p>                                
                        </div>
                        
                        <div>
                                <p>
                                    <h1>“이런 수업 어때요?”</h1>
                                    {window.innerWidth >= 640 && 
                                    <>선생님의 수업 고민에 동료 선생님이 답합니다.<br/>
            『수업 고민 사전』은 과목별 핵심 학습 표제어의 개념은 물론 현장 선생님들의 생생한 수업 노하우를 함께 제시한, 세상 어디에서도 볼 수 없었던 수업 안내 사전입니다.<br/>
            전국의 중고등학교 선생님이 학습 용어마다 ‘수업 고민 이렇게 해결해요!‘. ‘이런 활동 어때요?’, ‘학생 질문에는 이렇게!’, ‘함께 보면 좋은 자료＇의 내용을 집필해 주셨습니다.
                                    </>
                                    }
                                </p>
                        </div>
                        */}
                    </div>
                    <div className="dict2">
                        <button type="button" onClick={()=>navigate("/dictionary/0/0")}>
                            <img src="/images/Reading.svg" />
                            <h2>국어 수업 고민 사전</h2>
                            <Link to="/dictionary/0/0">바로 가기</Link>
                        </button>
                        <button type="button" onClick={()=>navigate("/dictionary/1/12")}>
                            <img src="/images/Globe.svg" />
                            <h2>사회 수업 고민 사전</h2>
                            <Link to="/dictionary/1/12">바로 가기</Link>
                        </button>
                        <button type="button" onClick={()=>navigate("/dictionary/2/13")}>
                            <img src="/images/Drawing.svg" />
                            <h2>미술 수업 고민 사전</h2>
                            <Link to="/dictionary/2/13">바로 가기</Link>
                        </button>
                    </div>
                </div>
            </div>
        </div>            
    );
}
