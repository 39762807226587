import React, { useEffect, useState, useRef } from 'react';
import { Routes, useNavigate, useLocation, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

export default function SnsNaver(props) {

   const params = useParams();
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const { naver } = window;
    const NAVER_CLIENT_ID = "i1P2ip0WNNvI44SaEY5p";
  
    // 네이버 로그인 기능 및 버튼 구현
    const naverLogin = new naver.LoginWithNaverId({
      clientId: NAVER_CLIENT_ID,
    });
    const getUser = async () => {
        await naverLogin.getLoginStatus((status) => {
          
          if (status) {
            //setUser({ ...naverLogin.user });
            //window.close();
   
            if(params.id == "1"){
              window.opener.postMessage({ type: 'naver_up', payload: naverLogin.user }, '*')
            } else {
              window.opener.postMessage({ type: 'naver', payload: naverLogin.user }, '*')
            }
            
            window.close();

            //console.log(naverLogin.user.name);
          }
        });
      };
    useEffect(() => {
      naverLogin.init();
      getUser();
    }, []);

  
    return <div className="sns_box">네이버 로그인</div>;
}
