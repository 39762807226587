import React, { useEffect, useState, useRef } from 'react';
import { Routes, useNavigate, useLocation, Link } from "react-router-dom";

import * as api from '../libs/api';

export default function(props) {

    const [category, setCategory] = useState([]); 

    const [categoryTab, setCategoryTab] = useState(); 
    const [categoryOpen, setCategoryOpen] = useState(false);    
    
    const location = useLocation();
    const navigate = useNavigate();
    
    
    function goUrl(url) {
        navigate(url);
    }
    const onCategory = (val)=>{
        props.setTitle(val.name)
        let link = "creative";
        if(props.type == 1){
            link = "book";
        }else if(props.type == 2){
            link = "teacher";
        }
        navigate("/"+link+"/list/"+val.categorySeq)
    }
    const onCategoryMobile = (e)=>{
        const ss = e.target.value.split("|");
        props.setTitle(ss[0])
        let link = "creative";
        if(props.type == 1){
            link = "book";
        }else if(props.type == 2){
            link = "teacher";
        }
        navigate("/"+link+"/list/"+ss[1])
    }
    useEffect(() => {
        api.getData("/category/"+props.type, {}).then(res=>{
            if(res && res?.data?.status=="SUCCESS"){
                setCategory(res.data.data);
            }
        });        
    }, [props.type]);

    return (
        <>
        <dl className={categoryOpen ? 'active' : ''}>
            {category.map((val) => (
                <dd className={(location.pathname.indexOf("list/"+val.categorySeq ) >=0 || location.pathname.indexOf("view/"+val.categorySeq ) >=0) ? 'active' : ''} onClick={(e)=>onCategory(val)}><span>{val.name}</span></dd>                            
            ))}
        </dl>
        <select onChange={(e)=>onCategoryMobile(e)}>
            {category.map((val) => (
                <option value={val.name+"|"+val.categorySeq}>{val.name}</option>                            
            ))}
        </select>
        </>
    );
}
