import React, { useEffect, useState, useRef, forwardRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { open, close } from '../../redux/popupSlice';
import images from "../../libs/images";
import routes from "../../libs/routes";


export default function Popup(props) {
    const { link, image, seq, setShowMainPop  } = props;
    const [info, setInfo] = useState();

    const dispatch = useDispatch();
    const navigate = useNavigate();


    useEffect(() => {       

    })

    const onPopupClose = (e,v) =>{
        setInfo(v);
    }
    const closeTodayPop = (v) => {
        let expires = new Date();
        expires = expires.setHours(expires.getHours() + 24);
        localStorage.setItem("homeVisited"+v, expires);
        setInfo(v);
    }
    
    return (
        <>
            { info!==seq && (
                <div className={"popup-main popup" + seq}  >
                    {link && link!='' ? 
                    <a href={link} ><img src={image} /></a>
                    :
                    <img src={image} />
                    }
                    <div className="popup-btns">
                        <button type="button" onClick={()=>closeTodayPop(seq)}>오늘은 그만 보기</button>
                        <button type="button" onClick={(e)=>onPopupClose(e,seq)}><img src='/images/popup.svg' />닫기</button>
                    </div>
                </div>
            )
            }
        </>
    );
}
