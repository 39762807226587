import React, { useEffect, useState, useRef } from 'react';
import { Routes, useNavigate, useLocation, Link } from "react-router-dom";
import images from "../../libs/images";
import InnerMenu from "../../components/innovation_menu";
import Banner from "../../components/banner";

export default function Page() {

    const location = useLocation();
    const navigate = useNavigate();


    function goUrl(url) {
        navigate(url);
    }
        

    return (
        <div className="changbi">
            <div className="wrap">
                <InnerMenu m_title="수업 혁신" />
                <Banner type="3"/>
            </div>
        </div>
    );
}
