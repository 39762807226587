/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import routes from '../libs/routes';
import images from "../libs/images";

export default function() {
    const message = "로그인이 필요한 서비스 입니다.";
    const button_name = "로그인";
    return (

        <div className="modal">
            <div className="modal-box">
                <a><img src='/images/exit-icon.svg' width="24"/></a>
                <p>{message}</p>
                <div>
                    <button tyhpe="button">닫기</button>
                    <button tyhpe="button">{button_name}</button>
                </div>
            </div>
        </div>
    )
}