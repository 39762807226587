import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { open, close } from '../redux/popupSlice';
import { Transition } from 'react-transition-group';

export default function Popup({}) {

    const dispatch = useDispatch();
    const { open, title, message, button, buttonCencle, onCancelPress, onPress, component } = useSelector(state => state.popupReducer);

    const handleClose = () => {
        dispatch(close());
    };
    
    return (
        <>
            <div className={open ? "popup show" : "popup"} >
                {component ?
                <div className="popup-box">{component}</div>
                :
                <div className="popup_container">
                    <div className="popup_top">
                        <div className="popup_title_section">
                            <p className="popup_title">{title}</p>
                            <button 
                                type="button" 
                                className="exit_btn"
                                onClick={() => {
                                    handleClose();
                                    onCancelPress && onCancelPress();
                                }}>
                                    
                            </button>
                        </div>
                        <div className="popup_msg">
                            <div className="sun-editor-editable" dangerouslySetInnerHTML={{__html: message}}></div>
                        </div>
                    </div>

                    <div className="popup_btn">
                        {buttonCencle && 
                            <button 
                                type="button" 
                                className="popbtn cencle_btn"
                                onClick={() => {
                                    handleClose();
                                    onCancelPress && onCancelPress();
                                }}>
                                {buttonCencle}
                            </button>
                        }
                        <button 
                            type="button" 
                            className="popbtn ok_btn"
                            onClick={() => {
                                handleClose();
                                onPress && onPress();
                            }}
                            style={ { width: buttonCencle ? '' : '100%'}}>
                            {button}
                        </button>
                    </div>

                </div>
                }
            </div>
        </>
    );
}